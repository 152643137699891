import React, { Suspense } from 'react';
import Amplify from 'aws-amplify';
import { signUpConfig, usernameAttributes } from './common/aws/cognito.config';
import awsconfig from './common/aws/aws-exports';
import { withAuthenticator } from 'aws-amplify-react';
import { client } from './common/aws/appsync-client';
import { ApolloProvider } from '@apollo/client';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';
import styled from 'styled-components';

import { PrivateRoute } from './utils/PrivateRoute';
import { MainHeading } from './layout/MainHeading';

import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import '@aws-amplify/ui/dist/style.css';
import './App.css';

import { GlobalStyle } from './GlobalStyle';
import {Keyframe} from './utils/animations';

import {Events} from './events/Events';

Amplify.configure(awsconfig)

export const MainContainer = styled.div`
  color: ${props => props.theme.colors.main};
  display: flex;
  position: relative;
  height: 100vh;
  width: 100vw;
  animation: ${Keyframe.fadeIn} 0.3s ease;
`;

function App() {
  return (
    //@ts-ignore
    <ApolloProvider client={client}>
      <Router>
        <div className="App">
          <MainContainer id={'main-container'}>
            <Suspense fallback={'loading'}>
              <GlobalStyle />
              <MainHeading />
              <Switch>
                <Route exact path="/">
                  <Redirect to={'events'} />
                </Route>
                <PrivateRoute requiredGroup={'admin'} path="/events">
                  <Events />
                </PrivateRoute>
                <Route path="/unauthorized">
                  <h1>Unauthorized</h1>
                </Route>
              </Switch>
            </Suspense>
          </MainContainer>
        </div>

      </Router>
    </ApolloProvider>
  );
}

//@ts-ignore
export default withAuthenticator(App, {
  signUpConfig,
  usernameAttributes
});