import React from 'react';
import { FullPage } from '../components/FullPage';
import { Label } from '../components/Label';
import { useHistory, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@apollo/client';
import {
  ADMIN_GET_FACTION_QUERY,
  ADMIN_UPDATE_FACTION_MUTATION,
  AdminFactionData,
  updateUpdateCache
} from '../graphql/factions';

import { useTranslation } from 'react-i18next';

type Inputs = {
  name: string,
  description: string,
};

export const EditFactionPage = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { eventId, factionId: id } = useParams();
  const { register, handleSubmit, errors, formState, watch, reset } = useForm<Inputs>({ mode: 'onChange' });

  const onFactionLoaded = ({ adminGetFaction: faction }) => {
    reset({
      name: faction.name,
      description: faction.description,
    })
    watch('name');
    watch('description');
  }

  const { loading: getEventLoading, data: getFactionData } = useQuery<AdminFactionData>(ADMIN_GET_FACTION_QUERY, {
    variables: { eventId, id },
    onCompleted: onFactionLoaded
  });

  const faction = getFactionData?.adminGetFaction;

  const [adminUpdateFaction, { loading: updateFactionLoading }] = useMutation(ADMIN_UPDATE_FACTION_MUTATION);

  const onSubmit = (formData) => {
    const updateFaction = {
      eventId,
      id,
      name: formData.name,
      description: formData.description,
    }

    adminUpdateFaction({
      variables: updateFaction,
      update: (client, result) => updateUpdateCache(eventId, client, result)
    }).then(() => {
      history.push(`/events/${eventId}/factions`);
    });

  };

  return (
    <FullPage
      heading={getEventLoading ? t('edit') : faction?.name as string}
      loading={getEventLoading || updateFactionLoading}
      closeHandler={() => history.push(`/events/${eventId}/factions`)}
    >
      <form onSubmit={handleSubmit(onSubmit)}>

        <Label htmlFor={'name'} hasError={errors.name ? 'true' : 'false'}>
          {t('factions.factionName')}{errors.name && ' - ' + t('errors.requiredField')}
        </Label>
        <input ref={register({ required: true })} type="text" id="name" name="name" placeholder={t('factions.factionName')} />

        <Label htmlFor={'description'} hasError={errors.description ? 'true' : 'false'}>
          {t('description')}{errors.description && ' - ' + t('errors.requiredField')}
        </Label>
        <input ref={register({ required: true })} type="text" id="description" name="description" placeholder={t('description')} />

        <input disabled={!formState.isValid} type="submit" value={t('factions.updateFaction') as string}/>
      </form>

    </FullPage>
  )
}
