import React, { useCallback, useContext, useRef, useEffect, useState, useMemo } from 'react';
import 'mapbox-gl/dist/mapbox-gl.css'
import ReactMapGL, { MapRef, ScaleControl, ViewState } from 'react-map-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { useQuery } from '@apollo/client';
import debounce from 'lodash/debounce'

import {GET_BODY_HEIGHT, GET_BODY_WIDTH} from '../graphql/layout';
import { MapStyleContext } from './MapStyleProvider';
import { MBCompositeSource } from './sources/MBCompositeSource';
import { MgrsLayer } from './layers/MgrsLayer';
import { EventAreaLayer } from '../graphql/events';
import { Feature } from 'geojson';
import { EventAreaContext } from './EventAreaProvider';
import { EventAreaLayerPreview } from './layers/EventAreaLayerPreview';

interface MapProps {
  layer: EventAreaLayer | undefined
  allLayers: EventAreaLayer[]
  layerUpdateCallback: (features: object, layerId: string) => Promise<any>
}

export const Map = (props: MapProps) => {
  const mapRef = useRef<MapRef>(null)
  const bodyHeightData = useQuery(GET_BODY_HEIGHT).data;
  const bodyWidthData = useQuery(GET_BODY_WIDTH).data;
  const { selectedStyleOption } = useContext(MapStyleContext)
  const { mapMode } = useContext(EventAreaContext)
  const [map, setMap] = React.useState<any | null>(null)
  const [draw, setDraw] = React.useState<MapboxDraw | null>(null)

  const { layer, allLayers, layerUpdateCallback } = props

  const [features, setFeatures] = useState({});

  console.log(Object.keys(features))

  const saveLayer = useCallback((newFeatures) => {
    const pathParts = window.location.pathname.split('/')
    const pathId = pathParts[pathParts.length -1]
    layerUpdateCallback(newFeatures, pathId)
  }, [layerUpdateCallback])

  const featuresSaveHandler = useMemo(
    () => debounce(saveLayer, 2000)
    , [saveLayer]);

  const onUpdate = useCallback(e => {
    let newValue
    setFeatures(currFeatures => {
      const newFeatures = {...currFeatures};
      for (const f of e.features) {
        newFeatures[f.id] = f;
      }
      newValue = newFeatures
      return newFeatures;
    });
    featuresSaveHandler(newValue)
  }, [featuresSaveHandler]);

  const onDelete = useCallback(e => {
    let newValue
    setFeatures(currFeatures => {
      const newFeatures = {...currFeatures};
      for (const f of e.features) {
        delete newFeatures[f.id];
      }
      newValue = newFeatures
      return newFeatures;
    });
    featuresSaveHandler(newValue)
  }, [featuresSaveHandler]);


  const [viewState, setViewState] = React.useState<ViewState>({
    latitude: 61.27792184,
    longitude: 25.2889142,
    zoom: 12,
    bearing: 0,
    pitch: 0,
    padding: { left: 0, top: 0, right: 0, bottom: 0}
  });

  const mapOnLoad = () => {
    const currentMap = mapRef.current!.getMap()
    setMap(currentMap)
    const draw = new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        polygon: true,
        line_string: true,
        trash: true
      }
    });
    setDraw(draw)
    currentMap.addControl(draw, 'top-right');
  }

  useEffect(() => {
    if(draw && layer?.featureCollection) {
      map?.on('draw.create', onUpdate);
      map?.on('draw.update', onUpdate);
      map?.on('draw.delete', onDelete);

      if(mapMode === 'draw' && map) {
        draw.add(layer.featureCollection)
      }
      const initialFeatures = layer?.featureCollection?.features.reduce((prev, next: Feature) => {
        prev[next.id!] = next
        return prev
      }, {})
      setFeatures(initialFeatures)
    }
    return () => {
      draw?.deleteAll()
      featuresSaveHandler.cancel()
      map?.off('draw.create', onUpdate);
      map?.off('draw.update', onUpdate);
      map?.off('draw.delete', onDelete);
    }
  }, [map, draw, layer, allLayers, mapMode, featuresSaveHandler, onUpdate, onDelete])

  //screen and (min-width: 768px) and (min-height: 768px)
  const isDesktop = bodyWidthData.bodyWidth > 768 && bodyHeightData.bodyHeight > 768;

  const viewPortStyle = {
    ...viewState,
    width: isDesktop ? bodyWidthData.bodyWidth - 300 : bodyWidthData.bodyWidth,
    height: bodyHeightData.bodyHeight - 16*4,
    maxZoom: 19
  }

  /*
  const mapOnClick = useCallback((pointerEvent: MapLayerMouseEvent) => {
    //mapRef.current?.flyTo({center: [pointerEvent.lngLat.lng, pointerEvent.lngLat.lat], duration: 500, zoom: 15});
    //history.push(`/point/${pointerEvent.lngLat.lng}/${pointerEvent.lngLat.lat}`)
  }, [mapRef, history])
*/

  return (
      <ReactMapGL
        ref={mapRef}
        onLoad={mapOnLoad}
        {...viewPortStyle}
        //@ts-ignore
        mapStyle={selectedStyleOption.style}
        onMove={evt => setViewState(evt.viewState)}
      >
        {mapMode === 'style' && allLayers.map(l => (
          <EventAreaLayerPreview key={l.id} layer={l} />
        ))}
        <ScaleControl maxWidth={150} position={'bottom-right'}/>
        { selectedStyleOption.id !== 'satellite' && (
          <MBCompositeSource>
            <MgrsLayer />
          </MBCompositeSource>
        )}
      </ReactMapGL>
  )
}
