import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  body {
    line-height: normal;
    line-height: ${props => props.theme.spacing.lineHeight}em;
    font-family: ${props => props.theme.font.family.main};
    font-size: ${props => props.theme.font.size.default};
    height: 100vh;
    width: 100vw;
    background-color: ${props => props.theme.colors.background.default};
    color: ${props => props.theme.colors.main};
    a {
      color: ${props => props.theme.colors.main};
      text-decoration: none;
      text-decoration-line: none;
      cursor: pointer;
    }
    a:hover {
      color: ${props => props.theme.colors.tertiary};
    }
    form {
      width: 100%;
      max-width: 768px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      align-self: center;
      margin: ${props => props.theme.spacing.margin}px 0;
    }
    input[type='submit']{
      user-select: none;
      outline: none;
      background: none;
      width: 100%;
      padding: ${props => props.theme.spacing.padding / 2}px ${props => props.theme.spacing.padding}px ${props => props.theme.spacing.padding / 2}px ${props => props.theme.spacing.padding / 4}px;
      color: ${props => props.theme.colors.main};
      font-size: ${props => props.theme.font.size.large};
      cursor: pointer;
      display: inline-block;
      border: ${props => props.theme.colors.main} 1px solid;
      border-radius: ${props => props.theme.spacing.borderRadius}px;
      &:disabled {
        border: ${props => props.theme.colors.background.light} 1px solid;
        color: ${props => props.theme.colors.background.light};
        cursor: not-allowed;
      }
      &:hover {
        border: ${props => props.theme.colors.background.light} 1px solid;
      }
    }
    select,
    input[type='text'],
    input[type='number'],
    input[type='textarea'],
    input[type='date'],
    input[type='time'] {
      font-family: ${props => props.theme.font.family.main};
      font-size: ${props => props.theme.font.size.default};
      background-color: ${props => props.theme.colors.background.default};
      color: ${props => props.theme.colors.main};
      -webkit-appearance: none;
      -moz-appearance: none;
      border: 2px solid ${props => props.theme.colors.background.light};
      
      width: 100%;
      padding: ${props => props.theme.spacing.margin / 2}px ${props => props.theme.spacing.margin}px;
      margin-bottom: ${props => props.theme.spacing.margin}px;
      box-sizing: border-box;
      
      -webkit-transition: 0.4s;
      transition: 0.4s;
      outline: none;
      &:focus {
        border: 2px solid ${props => props.theme.colors.main};
      }
    }
  }
`;
