import React from 'react';
import styled from 'styled-components';
import { IconButton } from './IconButton';
import {CloseIcon} from './icons';

interface BottomMenuStyledProps {
  readonly open: boolean
};

interface BottomMenuProps {
  readonly open: boolean
  children: any
  heading: string
  closeHandler: any
  refNode: any
};

const CloseButtonContainer = styled.div`
  position: absolute;
  top: ${props => props.theme.spacing.margin}px;
  left: ${props => props.theme.spacing.margin}px;
`;

const ActionList = styled.ul`
  list-style: none;
  padding: 0;
  margin-bottom: ${props => props.theme.spacing.margin}px;
  width: 100%;
`

const BottomMenuStyled = styled.div<BottomMenuStyledProps>`
  position: fixed;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.colors.background.dark};
  transform: ${({ open }) => open ? 'translateY(0)' : 'translateY(100%)'};
  transition: all 0.2s linear;
  z-index: 3;
  overflow-y: auto;
  width: 100vw;
  @media ${({ theme }) => theme.breakpoints.desktop} {
    width: calc(100% - 300px);
    left: 300px;
  }
`;

export const BottomMenu = (props: BottomMenuProps) => {
  return (
    <BottomMenuStyled ref={props.refNode} open={props.open}>
      <CloseButtonContainer>
        <IconButton size={'lg'} clickHandler={props.closeHandler}>
          <CloseIcon />
        </IconButton>
      </CloseButtonContainer>
      <h3>{props.heading}</h3>
      <ActionList>
        {props.children}
      </ActionList>
    </BottomMenuStyled>
  )
}
