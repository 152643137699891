import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useUser } from './auth';

const PrivateRoute = ({ requiredGroup, children, ...rest }) => {
  const user = useUser();

  // @ts-ignore
  const isAuthorized = user && user['cognito:groups'] ? user['cognito:groups'].includes(requiredGroup) : false;

  return user && (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthorized ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/unauthorized",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

export { PrivateRoute };