import React from 'react';
import styled from 'styled-components';
import {BackArrowIcon} from './icons';
import {IconButton} from './IconButton';

interface ContentPageHeadingProps {
  backClickHandler: any
  heading: string
};

const ContentPageHeadingStyled = styled.div`
  display: flex;
  width: 100%;
  margin-top: ${props => props.theme.spacing.margin*1.5}px;  
  h1 {
    margin: 0;
  }
  button {
    margin-right: ${props => props.theme.spacing.margin}px;
  }
`;

export const ContentPageHeading = (props: ContentPageHeadingProps) => {
  return (
    <ContentPageHeadingStyled>
      <IconButton clickHandler={props.backClickHandler} size={'lg'}>
        <BackArrowIcon />
      </IconButton>
      <h1>{props.heading}</h1>
    </ContentPageHeadingStyled>
  )
}
