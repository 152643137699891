import React, { useEffect } from 'react';
import debounce from 'lodash/debounce'
import { Heading } from '../components/Heading';
import { LogoWithText } from '../logo/LogoWithText';
import { MenuBurgerButton } from './MenuBurgerButton';
import { bodyHeightVar, bodyWidthVar } from '../graphql/cache';

export const MainHeading = () => {
  useEffect(() => {
    const onResize = () => {
      const element = document.getElementById('main-container');
      if (element) {
        bodyHeightVar(element.offsetHeight);
        bodyWidthVar(element.offsetWidth);
      }
    }
    onResize();
    window.addEventListener('resize', debounce(onResize, 300));
  },[])

  return (
    <Heading>
      <MenuBurgerButton />
      <LogoWithText />
    </Heading>
  )
}
