import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { EventAreaLayer } from '../graphql/events';


export interface IEventAreaContext {
  eventLayers: EventAreaLayer[],
  setEventLayers: (a: EventAreaLayer[]) => void
  selectedLayer: EventAreaLayer | null,
  setSelectedLayer: (a: EventAreaLayer) => void
  mapMode: 'draw' | 'style',
  setMapMode: (a: 'draw' | 'style') => void,
  updateLayer: (l: EventAreaLayer) => void,
  updateLayerFeatureCollection: (id: string, features: any[]) => void
}

export const EventAreaContext = React.createContext<IEventAreaContext>({
  eventLayers: [],
  setEventLayers: () => {},
  selectedLayer: null,
  setSelectedLayer:  () => {},
  mapMode: 'draw',
  setMapMode: () => {},
  updateLayer: () => {},
  updateLayerFeatureCollection: () => {}
});

export const EventAreaProvider = (props: any) => {
  const [eventLayers, setEventLayers] = useState<EventAreaLayer[]>([])
  const [mapMode, setMapMode] = useState<'draw' | 'style'>('draw')
  const { layerId } = useParams();
  const selectedLayer = eventLayers.find(layer => layer.id === layerId) || null

  const updateLayer = (layer: EventAreaLayer): void => {
    const index = eventLayers.findIndex(l => l.id === layer.id)
    eventLayers[index] = layer
    setEventLayers([...eventLayers])
  }


  const updateLayerFeatureCollection = (id: string, features: any[]): void => {
    console.log('updateLayerFeatureCollection', id, features)
  /*
    const index = eventLayers.findIndex(l => l.id === id)

    if(index >= 0) {
      const layer = eventLayers[index]
      const newFeatures = {...layer.features};

      for (const f of features) {
        newFeatures[f.id] = f;
      }

      eventLayers[index] = {
        ...layer,
        features: newFeatures
      }
      setEventLayers([...eventLayers])
    }

   */
  }

  return (
    //@ts-ignore
    <EventAreaContext.Provider value={{
      eventLayers,
      setEventLayers,
      selectedLayer,
      mapMode,
      setMapMode,
      updateLayer,
      updateLayerFeatureCollection
    }}>
      {props.children}
    </EventAreaContext.Provider>
  )
}
