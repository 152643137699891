import React from 'react';
import styled from 'styled-components';
import { Unit } from '../graphql/units';
import { sidcToSymbol } from '../utils/symbols';
import {DeviceIcon} from './icons';

interface OrbatListItemProps {
  unit: Unit
  clickHandler: any
};

const UnitSymbol = styled.img`
  height: 2em;
  margin-right: 0.5em;
  vertical-align: text-bottom;
`;

const Children = styled.ul`
`;
const OrbatListItemStyled = styled.li`
  svg {
    height: ${props => props.theme.spacing.lineHeight}em;
    width: ${props => props.theme.spacing.lineHeight}em;
    margin-bottom: ${props => props.theme.spacing.margin/2}px;
  }
`;

export const OrbatListItem = (props: OrbatListItemProps) => {

  return (
    <OrbatListItemStyled>

      <UnitSymbol src={`data:image/svg+xml;utf8,${sidcToSymbol(props.unit.sidc)}`} />
        { props.unit.device && (<DeviceIcon />)}
      <span onClick={() => props.clickHandler(props.unit)}>
        {props.unit.name}, {props.unit.additionalInfo}
      </span>
      {props.unit?.children && (
        <Children>
          { props.unit.children?.map(child => <OrbatListItem clickHandler={props.clickHandler} key={child.id} unit={child}/> )}
        </Children>
      )}
    </OrbatListItemStyled>
  )
}
