import React, { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid'
import { useTranslation } from 'react-i18next';
import { AddIcon } from '../components/icons';
import { EventAreaLayer, LayerTypeOption } from '../graphql/events';
import { Button } from '../components/Button';
import { LayerItem } from '../components/LayerItem';
import { LayerEditForm } from './LayerEditForm';

const DefaultPaintOptions = {
  line: {
    color: '#384FCE',
    opacity: 1.0,
    lineWidth: 2
  },
}

const LayersEditorStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1em;
  width: 420px;
  height: 100vh;
  background-color: #7e7e7e;
`

const LayersItemList = styled.ul`
  padding: 0;
`

interface LayersEditorProps {
  areaLayers: EventAreaLayer[]
  updateEventAreaLayersCallback: (layers: EventAreaLayer[]) => Promise<any>
}

export const LayersEditor = (props: LayersEditorProps) => {
  const history = useHistory();
  const { updateEventAreaLayersCallback, areaLayers } = props
  const { t } = useTranslation();

  const { eventId: id, layerId } = useParams();
  const selectedLayer = areaLayers.find(layer => layer.id === layerId)

  const layerUpdateCallback = useCallback((updateLayer: EventAreaLayer): Promise<any> => {
    const layerIndex = areaLayers.findIndex(layer => layer.id === updateLayer.id)
    areaLayers[layerIndex] = updateLayer
    return updateEventAreaLayersCallback(areaLayers)
  }, [areaLayers, updateEventAreaLayersCallback])

  const addNewLayer = useCallback(() => {
    const newLayer: EventAreaLayer = {
      id: uuidv4(),
      name: `${t('eventsArea.newLayer')}${areaLayers.length+1}`,
      type: LayerTypeOption.LINE,
      maxzoom: 20,
      minzoom: 10,
      paintOptions: DefaultPaintOptions[LayerTypeOption.LINE],
      featureCollection: {
        type: 'FeatureCollection',
        features: []
      }
    }
    updateEventAreaLayersCallback([...areaLayers, newLayer])
      .then(() => {
        history.push(`/events/${id}/area/layer/${newLayer.id}`)
      })
  }, [t, history, id, areaLayers, updateEventAreaLayersCallback])

  const deleteLayer = useCallback((e, deleteLayer: EventAreaLayer) => {
    e.preventDefault()
    e.stopPropagation()
    const newLayers = areaLayers.filter(layer => layer.id !== deleteLayer.id)
    updateEventAreaLayersCallback(newLayers)
      .then(() => {
        history.push(`/events/${id}/area`)
      })
  }, [id, history, areaLayers, updateEventAreaLayersCallback])

  const layerOnClick = useCallback((layer: EventAreaLayer) => {
    history.push(`/events/${id}/area/layer/${layer.id}`)
  }, [history, id])

  return (
    <LayersEditorStyled>
      <Button size={'lg'} clickHandler={() => addNewLayer()}>
        <AddIcon />
        <span>{t('eventsArea.newLayer')}</span>
      </Button>
      <LayersItemList>
        {areaLayers.map(layer => layer.id === selectedLayer?.id ? (
          <LayerEditForm
            key={selectedLayer.id}
            layer={layer}
            saveCallback={layerUpdateCallback}
            onDeleteClick={e => deleteLayer(e, layer)}
          />
        ) : (
          <LayerItem
            key={layer.id}
            name={layer.name}
            onClick={() => layerOnClick(layer)}
            onDeleteClick={e => deleteLayer(e, layer)}
          />
        ))}
      </LayersItemList>
    </LayersEditorStyled>
  )
}
