import React from 'react';
import { FullPage } from '../components/FullPage';
import { Label } from '../components/Label';
import { useHistory, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { useMutation, useQuery } from '@apollo/client';
import {
  ADMIN_UPDATE_EVENT_MUTATION,
  ADMIN_GET_EVENT_QUERY,
  AdminEventData,
  Event,
  updateUpdateCache
} from '../graphql/events';

import { useTranslation } from 'react-i18next';

type Inputs = {
  name: string,
  description: string,
  startTime: string,
  endTime: string,
};

const validateEndTime = (value, startTime) => {
  const end = dayjs(`${value} 23:59:59`);
  const start = dayjs(startTime);
  return end.isAfter(start)
}

export const EditEventPage = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { eventId: id } = useParams();
  const { register, handleSubmit, errors, formState, watch, reset } = useForm<Inputs>({ mode: 'onChange' });

  const onEventLoaded = ({ adminGetEvent: event }) => {
    reset({
      name: event.name,
      description: event.description,
      startTime: dayjs(event.startTime).format('YYYY-MM-DD'),
      endTime: dayjs(event.endTime).format('YYYY-MM-DD')
    })
    watch('name');
    watch('description');
    watch('startTime');
    watch('endTime');
  }

  const { loading: getEventLoading, data: getEventData } = useQuery<AdminEventData>(ADMIN_GET_EVENT_QUERY, {
    variables: { id },
    onCompleted: onEventLoaded
  });

  const event = getEventData?.adminGetEvent;

  const [adminUpdateEvent, { loading: updateEventLoading }] = useMutation(ADMIN_UPDATE_EVENT_MUTATION);

  const onSubmit = (formData) => {
    const updateEvent: Event = {
      id,
      name: formData.name,
      description: formData.description,
      startTime: dayjs(formData.startTime).unix() * 1000,
      endTime: dayjs(`${formData.endTime} 23:59:59`).unix() * 1000
    }

    adminUpdateEvent({
      variables: updateEvent,
      update: updateUpdateCache
    }).then(() => {
      history.push('/events');
    });

  };

  return (
    <FullPage
      heading={getEventLoading ? t('edit') : event?.name as string}
      loading={getEventLoading || updateEventLoading}
      closeHandler={() => history.push('/events')}
    >
      <form onSubmit={handleSubmit(onSubmit)}>

        <Label htmlFor={'name'} hasError={errors.name ? 'true' : 'false'}>
          {t('events.eventName')}{errors.name && ' - ' + t('errors.requiredField')}
        </Label>
        <input ref={register({ required: true })} type="text" id="name" name="name" placeholder={t('events.eventName')} />

        <Label htmlFor={'description'} hasError={errors.description ? 'true' : 'false'}>
          {t('description')}{errors.description && ' - ' + t('errors.requiredField')}
        </Label>
        <input ref={register({ required: true })} type="text" id="description" name="description" placeholder={t('description')} />

        <Label htmlFor={'startTime'} hasError={errors.startTime ? 'true' : 'false'}>
          {t('events.startTime')}{errors.name && ' - ' + t('errors.invalid')}
        </Label>
        <input ref={register({ validate: value => dayjs(value).isValid() })}
               id={'startTime'} type={'date'} name={'startTime'}/>

        <Label htmlFor={'endTime'} hasError={errors.endTime ? 'true' : 'false'}>
          {t('events.endTime')}{errors.endTime && ' - ' + t('errors.invalid')}
        </Label>
        <input ref={register({ validate: value =>  validateEndTime(value, watch('startTime'))})}
               id={'endTime'} type={'date'} name={'endTime'}/>

        <input disabled={!formState.isValid} type="submit" value={t('events.updateEvent') as string}/>
      </form>

    </FullPage>
  )
}
