import React from 'react';

import {PrivateRoute} from '../utils/PrivateRoute';
import {useQuery} from '@apollo/client';
import { useParams } from 'react-router-dom';

import {ADMIN_GET_UNITS_QUERY, AdminUnitsData} from '../graphql/units';
import {UnitsPage} from './UnitsPage';
import {NewUnitPage} from './NewUnitPage';
import {EditUnitPage} from './EditUnitPage';
import {DeleteUnitPage} from './DeleteUnitPage';


export const Units = () => {
  const { eventId, factionId  } = useParams();
  useQuery<AdminUnitsData>(ADMIN_GET_UNITS_QUERY, {
    variables: { eventId, factionId }
  });

  return (
    <>
      <PrivateRoute requiredGroup={'admin'} path="/events/:eventId/factions/:factionId/units">
        <UnitsPage />
      </PrivateRoute>
      <PrivateRoute requiredGroup={'admin'} path="/events/:eventId/factions/:factionId/units/new">
        <NewUnitPage />
      </PrivateRoute>
      <PrivateRoute requiredGroup={'admin'} path="/events/:eventId/factions/:factionId/units/:unitId/edit">
        <EditUnitPage />
      </PrivateRoute>
      <PrivateRoute requiredGroup={'admin'} path="/events/:eventId/factions/:factionId/units/:unitId/delete">
        <DeleteUnitPage />
      </PrivateRoute>
    </>
  )
}
