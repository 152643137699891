import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { MapStyleProvider } from './MapStyleProvider';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { ADMIN_GET_EVENT_QUERY, AdminEventData } from '../graphql/events';
import { FullPage } from '../components/FullPage';
import { MapEditor } from './MapEditor';
import { EventAreaProvider } from './EventAreaProvider';
import { MapModeButton } from './MapModeButton';
import styled from 'styled-components';

const HeadingContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: baseline;
`

export const EventArea = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { eventId: id } = useParams();

  const onEventLoaded = ({ adminGetEvent: event }) => {

  }

  const { loading: getEventLoading, data: getEventData } = useQuery<AdminEventData>(ADMIN_GET_EVENT_QUERY, {
    variables: { id },
    onCompleted: onEventLoaded
  });

  const event = getEventData?.adminGetEvent;

  return (
    <MapStyleProvider>
      <EventAreaProvider>
        <FullPage
          heading={getEventLoading ? t('edit') : event?.name as string}
          loading={getEventLoading}
          closeHandler={() => history.push('/events')}
        >
          <HeadingContainer>
            <h1>Event Area Map Editor</h1>
            <MapModeButton />
          </HeadingContainer>
          <MapEditor />
        </FullPage>
      </EventAreaProvider>
    </MapStyleProvider>
  )
}
