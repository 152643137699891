import { gql } from '@apollo/client';
import {ADMIN_GET_EVENTS_QUERY} from './events';

export interface Faction {
  id: number
  name: string
  description: string
}

export interface CreateFaction {
  eventId: number
  name: string
  description: string
}

export interface AdminFactionsData {
  adminGetFactions: Faction[]
}

export interface AdminFactionData {
  adminGetFaction: Faction
}

const ADMIN_GET_FACTIONS_QUERY = gql`
  query AdminGetFactions($eventId: ID!) {
    adminGetFactions(eventId: $eventId) { 
      id
      name
      description
    }
  } 
  `;

const ADMIN_GET_FACTION_QUERY = gql`
  query AdminGetFaction($eventId: ID!, $id: ID!) {
    adminGetFaction(eventId: $eventId, id: $id) { 
      id
      name
      description
    }
  } 
  `;

const ADMIN_CREATE_FACTION_MUTATION = gql`
  mutation AdminCreateFaction($eventId: ID!, $name: String!, $description: String) {
    adminCreateFaction(eventId: $eventId, name: $name, description: $description) { 
      id
      name
      description          
    }
  } 
  `;

const ADMIN_UPDATE_FACTION_MUTATION = gql`
  mutation AdminUpdateFaction($eventId: ID!, $id: ID!, $name: String!, $description: String) {
    adminUpdateFaction(eventId: $eventId, id: $id, name: $name, description: $description) { 
      id
      name
      description          
    }
  } 
  `;

const ADMIN_DELETE_FACTION_MUTATION = gql`
  mutation AdminDeleteFaction($eventId: ID!, $id: ID!) {
    adminDeleteFaction(eventId: $eventId, id: $id) { 
      id
      name
      description          
    }
  } 
  `;

const sortFactionsByNameAsc = (a, b) => {
  const nameA = a.name.toUpperCase(); // ignore upper and lowercase
  const nameB = b.name.toUpperCase(); // ignore upper and lowercase
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  // names must be equal
  return 0;
};

const createUpdateCache = (eventId, client, result) => {
  const { adminCreateFaction } = result.data;
  const data = client.readQuery({
    query: ADMIN_GET_EVENTS_QUERY,
  });
  const newData = {
    adminGetEvents: data.adminGetEvents
      .map(event => {
        if(event.id === eventId) {
          return {
            ...event,
            factions: [...event.factions, adminCreateFaction].sort(sortFactionsByNameAsc)
          }
        }
        return event;
      })
  }
  client.writeQuery({
    query: ADMIN_GET_EVENTS_QUERY,
    data: newData
  });
}

const deleteUpdateCache = (eventId, client, result) => {
  const { adminDeleteFaction } = result.data;
  const data = client.readQuery({
    query: ADMIN_GET_EVENTS_QUERY,
  });
  const newData = {
    adminGetEvents: data.adminGetEvents
      .map(event => {
        if(event.id === eventId) {
          return {
            ...event,
            factions: event.factions
              .filter(faction => faction.id !== adminDeleteFaction.id)
              .sort(sortFactionsByNameAsc)
          }
        }
        return event;
      })
  }
  client.writeQuery({
    query: ADMIN_GET_EVENTS_QUERY,
    data: newData
  });
}

const updateUpdateCache = (eventId, client, result) => {
  const { adminUpdateFaction } = result.data;
  const data = client.readQuery({
    query: ADMIN_GET_EVENTS_QUERY,
  });
  const newData = {
    adminGetEvents: data.adminGetEvents
      .map(event => {
        if(event.id === eventId) {
          return {
            ...event,
            factions: event.factions
              .map(faction => faction.id === adminUpdateFaction.id ? adminUpdateFaction : faction)
              .sort(sortFactionsByNameAsc)
          }
        }
        return event;
      })
  }
  client.writeQuery({
    query: ADMIN_GET_EVENTS_QUERY,
    data: newData
  });
}

export {
  ADMIN_GET_FACTIONS_QUERY,
  ADMIN_GET_FACTION_QUERY,
  ADMIN_CREATE_FACTION_MUTATION,
  ADMIN_UPDATE_FACTION_MUTATION,
  ADMIN_DELETE_FACTION_MUTATION,
  createUpdateCache,
  deleteUpdateCache,
  updateUpdateCache
};