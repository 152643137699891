import React from 'react';
import styled from 'styled-components';

import { IconButton } from './IconButton';
import { Device } from '../graphql/devices';
import { DeviceIcon, CloseIcon } from './icons';

interface DeviceSelectorProps {
  availableDevices: Device[]
  device?: Device
  onDeviceChange: any
  onRemove: any,
  trackerText: string
  selectTrackerText: string
}

const DeviceSelectorStyled = styled.div`
  display: flex;
  align-items: center;
  padding: ${props => props.theme.spacing.padding}px;
  button {
    margin-left: ${props => props.theme.spacing.margin}px;
  }
  select {
    margin: 0;
  }
`;

export const DeviceSelector = (props: DeviceSelectorProps) => {
  const component = props.device ? (
    <DeviceSelectorStyled>
      <DeviceIcon />
      <span>{props.device?.name}</span>
      <IconButton clickHandler={() => props.onRemove(props.device)} size={'lg'}><CloseIcon /></IconButton>
    </DeviceSelectorStyled>
  ) : (
    <DeviceSelectorStyled>
      <DeviceIcon />
      <select id="device" onChange={props.onDeviceChange}>
        <option value={0}>{props.selectTrackerText}</option>
        {
          props.availableDevices.map(device => (
            <option key={device.id} value={device.id}>{device.name}</option>
          ))
        }
      </select>
    </DeviceSelectorStyled>
  )
  return component;
}
