import React from 'react';
import {PrivateRoute} from '../utils/PrivateRoute';
import {NewEventPage} from './NewEventPage';
import {DeleteEventPage} from './DeleteEventPage';
import {EditEventPage} from './EditEventPage';
import {EventsPage} from './EventsPage';
import {useQuery} from '@apollo/client';
import {ADMIN_GET_EVENTS_QUERY, AdminEventsData} from '../graphql/events';
import {Factions} from '../factions/Factions';
import { EventArea } from '../event-area/EventArea';

export const Events = () => {
  useQuery<AdminEventsData>(ADMIN_GET_EVENTS_QUERY);
  return (
    <>
      <PrivateRoute exact requiredGroup={'admin'} path="/events">
        <EventsPage />
      </PrivateRoute>
      <PrivateRoute requiredGroup={'admin'} path="/events/new">
        <NewEventPage />
      </PrivateRoute>
      <PrivateRoute requiredGroup={'admin'} path="/events/:eventId/delete">
        <DeleteEventPage />
      </PrivateRoute>
      <PrivateRoute requiredGroup={'admin'} path="/events/:eventId/edit">
        <EditEventPage />
      </PrivateRoute>
      <PrivateRoute requiredGroup={'admin'} path="/events/:eventId/factions">
        <Factions />
      </PrivateRoute>
      <PrivateRoute requiredGroup={'admin'} path="/events/:eventId/area">
        <EventArea />
      </PrivateRoute>
      <PrivateRoute requiredGroup={'admin'} path="/events/:eventId/area/layer/:layerId">
        <EventArea />
      </PrivateRoute>
    </>
  )
}
