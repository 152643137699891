import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory, Link } from 'react-router-dom';
import { PageContent } from '../components/PageContent';
import { useQuery } from '@apollo/client';
import { useOnClickOutside } from '../hooks';

import {
  Event,
  AdminEventData,
  ADMIN_GET_EVENT_QUERY
} from '../graphql/events';
import { FactionTableRow } from './FactionTableRow';
import { BottomMenu } from '../components/BottomMenu';
import { ActionMenuItem } from '../components/ActionMenuItem';
import { Button } from '../components/Button';
import { Table } from '../components/Table';
import { AddIcon, DeleteIcon, EditIcon } from '../components/icons';
import {ContentPageHeading} from '../components/ContentPageHeading';

export const FactionsPage = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const { eventId } = useParams();

  const [selectedFaction, setSelectedFaction] = useState<Event>();
  const [bottomMenuOpen, setBottomMenuOpen] = useState<boolean>(false);
  const node = useRef() as React.MutableRefObject<HTMLDivElement>;

  const { loading: getEventLoading, data: adminEventData } = useQuery<AdminEventData>(ADMIN_GET_EVENT_QUERY, {variables: { id: eventId }});

  const event = adminEventData?.adminGetEvent;
  const loading = getEventLoading

  const actionMenuClick = faction => {
    setSelectedFaction(faction);
    setBottomMenuOpen(true);
  }
  const closeHandler = bottomMenuOpen => {
    if(bottomMenuOpen) {
      setSelectedFaction(undefined);
      setBottomMenuOpen(false);
    }
  }
  useOnClickOutside(node, () => closeHandler(bottomMenuOpen));

  return (
    <PageContent loading={loading} heading={t('factions.pageHeader')}>
      <ContentPageHeading backClickHandler={() => history.push('/events')} heading={event?.name as string}/>
      <h2>{t('factions.pageHeader')}</h2>
      <Button size={'lg'} clickHandler={() => history.push(`/events/${eventId}/factions/new`)}>
        <AddIcon />
        <span>{t('factions.newFaction')}</span>
      </Button>

      <Table>
        <tbody>
        { event?.factions?.map(faction => (
          <FactionTableRow key={faction.id} eventId={eventId} faction={faction} actionMenuClick={actionMenuClick} />
        )) }
        </tbody>
      </Table>
      <BottomMenu
        refNode={node}
        closeHandler={closeHandler}
        heading={selectedFaction?.name || ''}
        open={bottomMenuOpen}
      >
        <ActionMenuItem>
          <EditIcon />
          <Link to={`/events/${eventId}/factions/${selectedFaction?.id}/edit`}>
            {t('edit')}
          </Link>
        </ActionMenuItem>
        <ActionMenuItem>
          <DeleteIcon />
          <Link to={`/events/${eventId}/factions/${selectedFaction?.id}/delete`}>{t('delete')}</Link>
        </ActionMenuItem>
      </BottomMenu>
    </PageContent>
  )
}
