import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { SketchPicker } from 'react-color'

interface ColorPickerButtonProps {
  onChangeCallback: (color: string) => any
  initialColor: string
};

interface ButtonStyledProps {
  size: 'lg' | 'sm'
  color: string
};

const PopOver = styled.div`
  position: absolute;
  z-index: 2;
`

const CloseCover = styled.div`
  position: fixed;
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
`

const ButtonStyled = styled.button<ButtonStyledProps>`
  user-select: none;
  outline: none;
  background: none;
  padding: ${props => props.theme.spacing.padding / 2}px ${props => props.theme.spacing.padding}px;
  color: ${props => props.theme.colors.main};
  background-color: ${props => props.color};
  font-size: ${props => props.theme.font.size.large};
  cursor: pointer;
  display: inline-block;
  border: ${props => props.theme.colors.main} 1px solid;
  border-radius: ${props => props.theme.spacing.borderRadius}px;
  
  &:hover {
    border: ${props => props.theme.colors.background.light} 1px solid;
  }
`;

export const ColorPickerButton = (props: ColorPickerButtonProps) => {
  const { initialColor, onChangeCallback } = props
  const [color, setColor] = useState<string>(initialColor)
  const [open, setOpen] = useState<boolean>(false)

  const onButtonClick = useCallback(() => {
    setOpen(!open)
  }, [open])

  const onCloseClick = useCallback(() => {
    setOpen(false)
  }, [])

  const onColorChange = useCallback((color, event) => {
    setColor(color.hex)
    onChangeCallback(color.hex)
  }, [onChangeCallback])

  return open ? (
    <PopOver>
      <CloseCover onClick={onCloseClick} />
      <SketchPicker color={ color } onChange={ onColorChange } />
    </PopOver>
  ) : (
    <ButtonStyled size={'lg'} onClick={onButtonClick} color={color}>
      Pick Color
    </ButtonStyled>
  )
}
