import React from 'react';
import { Event } from '../graphql/events';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { ThreeDotButton } from '../components/ThreeDotButton';
import { TableRow } from '../components/TableRow';

interface EventTableRowProps {
  event: Event
  actionMenuClick: any
};

export const EventTableRow = (props: EventTableRowProps) => {
  const startTime = dayjs(props.event.startTime)
  return (
    <TableRow>
      <td>
        <Link to={`/events/${props.event.id}/factions`}>{props.event.name}</Link>
      </td>
      <td>{startTime.format('DD.MM.YYYY')}</td>
      <td><ThreeDotButton clickHandler={() => props.actionMenuClick(props.event)} /></td>
    </TableRow>
  )
}
