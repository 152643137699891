import React from 'react';
import styled from 'styled-components';

interface ActionMenuItemProps {
  children: any
};

const ActionMenuItemStyled = styled.li`
  display: flex;
  margin-bottom: ${props => props.theme.spacing.margin*2}px;
  font-size: ${props => props.theme.font.size.large};
  justify-content: center;
  line-height: ${props => props.theme.spacing.default*1.5}px;
  svg {
    margin-right: ${props => props.theme.spacing.margin / 2}px;
  }
`;

export const ActionMenuItem = (props: ActionMenuItemProps) => {

  return (
    <ActionMenuItemStyled>
      {props.children}
    </ActionMenuItemStyled>
  )
}
