import React from 'react';

import { FullPage } from '../components/FullPage';
import { Label } from '../components/Label';
import { useHistory, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import {ADMIN_CREATE_FACTION_MUTATION, CreateFaction, createUpdateCache} from '../graphql/factions';

type Inputs = {
  name: string,
  description: string,
};

export const NewFactionPage = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { eventId } = useParams();
  const { register, handleSubmit, errors, formState } = useForm<Inputs>({ mode: 'onChange'});
  const [adminCreateFaction, { loading }] = useMutation(ADMIN_CREATE_FACTION_MUTATION);

  const onSubmit = (formData) => {
    const createFaction: CreateFaction = {
      eventId: eventId,
      name: formData.name,
      description: formData.description
    }

    adminCreateFaction({
      variables: createFaction,
      update: (client, result) => createUpdateCache(eventId, client, result)
    }).then(() => {
      history.push(`/events/${eventId}/factions`)
    });
  };

  return (
    <FullPage
      heading={t('factions.newFaction')}
      loading={loading}
      closeHandler={() => history.push(`/events/${eventId}/factions`)}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Label htmlFor={'name'} hasError={errors.name ? 'true' : 'false'}>
          {t('factions.factionName')}{errors.name && ' - ' + t('errors.requiredField')}
        </Label>
        <input ref={register({ required: true })} type="text" id="name" name="name" placeholder={t('factions.factionName')} />

        <Label htmlFor={'description'} hasError={errors.description ? 'true' : 'false'}>
          {t('description')}{errors.description && ' - ' + t('errors.requiredField')}
        </Label>
        <input ref={register({ required: true })} type="text" id="description" name="description" placeholder={t('description')} />

        <input disabled={!formState.isValid} type="submit" value={t('factions.createFaction') as string}/>
      </form>

    </FullPage>
  )
}
