import React from 'react';

import { FullPage } from '../components/FullPage';
import { Label } from '../components/Label';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { useMutation } from '@apollo/client';
import {
  ADMIN_CREATE_EVENT_MUTATION,
  CreateEvent,
  createUpdateCache
} from '../graphql/events';

import { useTranslation } from 'react-i18next';

type Inputs = {
  name: string,
  description: string,
  startTime: string,
  endTime: string,
};

const validateEndTime = (value, startTime) => {
  const end = dayjs(`${value} 23:59:59`);
  const start = dayjs(startTime);
  return end.isAfter(start)
}

export const NewEventPage = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { register, handleSubmit, errors, formState, watch } = useForm<Inputs>({ mode: 'onChange'});
  const [adminCreateEvent, { loading }] = useMutation(ADMIN_CREATE_EVENT_MUTATION);

  const onSubmit = (formData) => {
    const createEvent: CreateEvent = {
      name: formData.name,
      description: formData.description,
      startTime: dayjs(formData.startTime).unix() * 1000,
      endTime: dayjs(`${formData.endTime} 23:59:59`).unix() * 1000
    }

    adminCreateEvent({
      variables: createEvent,
      update: createUpdateCache
    }).then(() => {
      history.push('/events')
    });

  };

  return (
    <FullPage
      heading={t('events.newEvent')}
      loading={loading}
      closeHandler={() => history.push('/events')}
    >
      <form onSubmit={handleSubmit(onSubmit)}>

        <Label htmlFor={'name'} hasError={errors.name ? 'true' : 'false'}>
          {t('events.eventName')}{errors.name && ' - ' + t('errors.requiredField')}
        </Label>
        <input ref={register({ required: true })} type="text" id="name" name="name" placeholder={t('events.eventName')} />

        <Label htmlFor={'description'} hasError={errors.description ? 'true' : 'false'}>
          {t('description')}{errors.description && ' - ' + t('errors.requiredField')}
        </Label>
        <input ref={register({ required: true })} type="text" id="description" name="description" placeholder={t('description')} />

        <Label htmlFor={'startTime'} hasError={errors.startTime ? 'true' : 'false'}>
          {t('events.startTime')}{errors.name && ' - ' + t('errors.invalid')}
        </Label>
        <input ref={register({ validate: value => dayjs(value).isValid() })}
               id={'startTime'} type={'date'} name={'startTime'}/>


        <Label htmlFor={'endTime'} hasError={errors.endTime ? 'true' : 'false'}>
          {t('events.endTime')}{errors.endTime && ' - ' + t('errors.invalid')}
        </Label>
        <input ref={register({ validate: value =>  validateEndTime(value, watch('startTime'))})}
               id={'endTime'} type={'date'} name={'endTime'}/>

        <input disabled={!formState.isValid} type="submit" value={t('events.createEvent') as string}/>
      </form>

    </FullPage>
  )
}
