import React from 'react';
import styled from 'styled-components';
import { FullPage } from '../components/FullPage';
import { useParams, useHistory } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { Delete } from '@styled-icons/material/Delete';

import {
  ADMIN_DELETE_EVENT_MUTATION,
  ADMIN_GET_EVENT_QUERY,
  deleteUpdateCache,
  AdminEventData
} from '../graphql/events';
import { useTranslation } from 'react-i18next';
import {Button} from '../components/Button';

const StyledDeletePage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const DeleteIcon = styled(Delete)`
  color: ${props => props.theme.colors.main};
  height: ${props => props.theme.spacing.default*2}px;
  width: ${props => props.theme.spacing.default*2}px;
`

export const DeleteEventPage = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { eventId: id } = useParams();
  const { loading: getEventLoading, data: getEventData } = useQuery<AdminEventData>(ADMIN_GET_EVENT_QUERY, { variables: { id }});

  const [adminDeleteEvent, { loading: deleting }] = useMutation(ADMIN_DELETE_EVENT_MUTATION);

  const onDelete = () => {
    adminDeleteEvent({
      variables: { id },
      update: deleteUpdateCache
    })
      .then(() => {
        history.push('/events')
      })
  }

  return (
    <FullPage
      heading={deleting ? t('delete'): getEventData?.adminGetEvent.name as string}
      loading={deleting || getEventLoading}
      closeHandler={() => history.push('/events')}
    >
      <StyledDeletePage>
        <h2>{t('delete')} {getEventData?.adminGetEvent.name}?</h2>
        <Button clickHandler={onDelete} size={'lg'}><DeleteIcon /> {t('delete')}</Button>
      </StyledDeletePage>
    </FullPage>
  )
}
