import React from 'react';
import { Link } from 'react-router-dom';
import { Faction } from '../graphql/factions';
import { ThreeDotButton } from '../components/ThreeDotButton';
import { TableRow } from '../components/TableRow';

interface FactionTableRowProps {
  eventId: number
  faction: Faction
  actionMenuClick: any
};

export const FactionTableRow = (props: FactionTableRowProps) => {
  return (
    <TableRow>
      <td>
        <Link to={`/events/${props.eventId}/factions/${props.faction.id}/units`}>{props.faction.name}</Link>
      </td>
      <td><ThreeDotButton clickHandler={() => props.actionMenuClick(props.faction)} /></td>
    </TableRow>
  )
}
