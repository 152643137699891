import React, { useState } from 'react';

import { FullPage } from '../components/FullPage';
import { Label } from '../components/Label';
import { useHistory, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import {useMutation, useQuery} from '@apollo/client';
import { useTranslation } from 'react-i18next';
import {
  ADMIN_GET_UNIT_QUERY,
  ADMIN_UPDATE_UNIT_MUTATION,
  ADMIN_GET_UNITS_QUERY,
  AdminUnitsData,
  UpdateUnit,
  updateUpdateCache, Unit, unitsToTree, AdminUnitData
} from '../graphql/units';

import {
  functionIdOptions, getSidcFunctionId,
  getSidcUnitSize,
  setSidcFunctionId,
  setSidcUnitSize,
  sidcToSymbol,
  unitSizeOptions
} from '../utils/symbols';
import styled from 'styled-components';

type Inputs = {
  parentId: number,
  name: string,
  sidc: string,
  additionalInfo: string,
};

interface ParentOption {
  id: number
  name: string
}

const UnitSymbolContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: ${props => props.theme.spacing.margin}px;
`;

const UnitSymbol = styled.img`
  height: 5em;
`;

const unitToOptions = (prefix: string, unit: Unit) : ParentOption[] => {
  const name = `${prefix}${unit.name}`
  const option: ParentOption = {
    id: unit.id,
    name
  }
  const childOptions = unit.children?.map(child => unitToOptions(`${name} > `, child))
    .reduce((prev, cur) => {
      return prev.concat(cur)
    }, []) || [];
  return [option, ...childOptions];
}

export const EditUnitPage = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [parentOptions, setParentOptions] = useState<ParentOption[]>([]);
  const [unitSize, setUnitSize] = useState<string>('-');
  const [unitFunction, setUnitFunction] = useState<string>('U');
  const { eventId, factionId, unitId } = useParams();

  const { register, handleSubmit, errors, formState, setValue, watch, reset } = useForm<Inputs>({mode: 'onChange'});

  const unitsOnCompleted = (data: AdminUnitsData) => {
    const rawUnits = data.adminGetUnits.filter(unit => unit.id !== unitId);
    const unitTree = unitsToTree(rawUnits);
    const parentOptions = unitTree
      .map(unit => unitToOptions('', unit))
      .reduce((prev, cur) => {
        return prev.concat(cur);
      },[]);
    const rootOption: ParentOption = {
      id: 0,
      name: t('units.root')
    }
    setParentOptions([rootOption, ...parentOptions]);
  }

  const onUnitLoaded = ({ adminGetUnit: unit } : AdminUnitData) => {
    reset({
      parentId: unit.parentId || 0,
      name: unit.name,
      sidc: unit.sidc,
      additionalInfo: unit.additionalInfo
    });
    setUnitSize(getSidcUnitSize(unit.sidc));
    setUnitFunction(getSidcFunctionId(unit.sidc));
  }

  const { loading: getUnitLoading, data: getUnitData } = useQuery<AdminUnitData>(ADMIN_GET_UNIT_QUERY, {
    variables: { eventId, factionId, id: unitId },
    onCompleted: onUnitLoaded
  });

  const sidc = watch('sidc');
  const unitName = watch('name');
  const additionalInfo = watch('additionalInfo');
  const parentId = watch('parentId');

  const unitSizeChanged = ({ target }) => {
    setUnitSize(target.value);
    const newSidc = setSidcUnitSize(sidc, target.value);
    setValue('sidc', newSidc);
  }

  const unitFunctionChanged = ({ target }) => {
    setUnitFunction(target.value);
    const newSidc = setSidcFunctionId(sidc, target.value);
    setValue('sidc', newSidc);
  }

  const sizeOptions = unitSizeOptions(t);
  const functionOptions = functionIdOptions(t);

  const { loading: unitsLoading } = useQuery<AdminUnitsData>(ADMIN_GET_UNITS_QUERY, {
    variables: { eventId, factionId },
    onCompleted: unitsOnCompleted
  });
  const [adminUpdateUnit, { loading }] = useMutation(ADMIN_UPDATE_UNIT_MUTATION);

  const onSubmit = (formData) => {
    const updateUnit: UpdateUnit = {
      id: unitId,
      eventId,
      factionId,
      parentId: formData.parentId,
      name: formData.name,
      sidc: formData.sidc,
      additionalInfo: formData.additionalInfo
    }

    adminUpdateUnit({
      variables: updateUnit,
      update: (client, result) => updateUpdateCache(eventId, factionId, client, result)
    }).then(() => {
      history.push(`/events/${eventId}/factions/${factionId}/units`)
    });
  };

  return (
    <FullPage
      heading={getUnitLoading ? t('edit') : getUnitData?.adminGetUnit.name as string}
      loading={loading || unitsLoading || getUnitLoading}
      closeHandler={() => history.push(`/events/${eventId}/factions/${factionId}/units`)}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <UnitSymbolContainer>
          <UnitSymbol src={`data:image/svg+xml;utf8,${sidcToSymbol(sidc, unitName, additionalInfo)}`} />
        </UnitSymbolContainer>

        <Label htmlFor={'sidc'} hasError={errors.sidc ? 'true' : 'false'}>
          {t('sidc')}{errors.sidc && ' - ' + t('errors.invalid')}
        </Label>
        <input ref={register({ required: true, maxLength: 12, minLength: 12 })} type="text" id="sidc" name="sidc" placeholder={t('sidc')} />

        <Label htmlFor={'unitSize'} hasError={'false'}>
          {t('units.unitSize')}
        </Label>
        <select value={unitSize} onChange={unitSizeChanged} name="unitSize">
          {
            sizeOptions.map(option => (
              <option key={option.id} value={option.id}>{option.name}</option>
            ))
          }
        </select>

        <Label htmlFor={'unitFunction'} hasError={'false'}>
          {t('units.unitFunction')}
        </Label>
        <select id="unitFunction" value={unitFunction} onChange={unitFunctionChanged} name="unitFunction">
          {
            functionOptions.map(option => (
              <option key={option.id} value={option.id}>{option.name}</option>
            ))
          }
        </select>

        <Label htmlFor={'parentId'} hasError={'false'}>
          {t('units.parent')}
        </Label>
        <select ref={register} defaultValue={parentId} name="parentId">
          {
            parentOptions.map(option => (
              <option key={option.id} value={option.id}>{option.name}</option>
            ))
          }
        </select>
        <Label htmlFor={'name'} hasError={errors.name ? 'true' : 'false'}>
          {t('units.unitName')}{errors.name && ' - ' + t('errors.requiredField')}
        </Label>
        <input ref={register({ required: true })} type="text" id="name" name="name" placeholder={t('units.unitName')} />

        <Label htmlFor={'additionalInfo'} hasError={'false'}>
          {t('units.additionalInfo')}
        </Label>
        <input ref={register()} type="text" id="additionalInfo" name="additionalInfo" placeholder={t('units.additionalInfo')} />

        <input disabled={!formState.isValid} type="submit" value={t('units.updateUnit') as string}/>
      </form>

    </FullPage>
  )
}
