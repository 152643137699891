import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, Link } from 'react-router-dom';
import { PageContent } from '../components/PageContent';
import { useQuery } from '@apollo/client';
import { useOnClickOutside } from '../hooks';
import { AddIcon, DeleteIcon, EditIcon, MapIcon } from '../components/icons';

import {
  Event,
  ADMIN_GET_EVENTS_QUERY,
  AdminEventsData
} from '../graphql/events';
import { EventTableRow } from './EventTableRow';
import { BottomMenu } from '../components/BottomMenu';
import { ActionMenuItem } from '../components/ActionMenuItem';
import { Button } from '../components/Button';
import { Table } from '../components/Table';

export const EventsPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { loading, data } = useQuery<AdminEventsData>(ADMIN_GET_EVENTS_QUERY);
  const [selectedEvent, setSelectedEvent] = useState<Event>();
  const [bottomMenuOpen, setBottomMenuOpen] = useState<boolean>(false);
  const node = useRef() as React.MutableRefObject<HTMLDivElement>;

  const actionMenuClick = event => {
    setSelectedEvent(event);
    setBottomMenuOpen(true);
  }
  const closeHandler = bottomMenuOpen => {
    if(bottomMenuOpen) {
      setSelectedEvent(undefined);
      setBottomMenuOpen(false);
    }
  }
  useOnClickOutside(node, () => closeHandler(bottomMenuOpen));

  return (
    <PageContent loading={loading} heading={t('events.pageHeader')}>

      <h1>{t('events.pageHeader')}</h1>
      <Button size={'lg'} clickHandler={() => history.push('/events/new')}>
        <AddIcon />
        <span>{t('events.newEvent')}</span>
      </Button>

      <Table>
        <tbody>
          { data && data.adminGetEvents.map(event => (
            <EventTableRow key={event.id} event={event} actionMenuClick={actionMenuClick} />
          )) }
        </tbody>
      </Table>
      <BottomMenu
        refNode={node}
        closeHandler={closeHandler}
        heading={selectedEvent?.name || ''}
        open={bottomMenuOpen}
      >
        <ActionMenuItem>
          <MapIcon />
          <Link to={`/events/${selectedEvent?.id}/area`}>
            {t('eventsArea.edit')}
          </Link>
        </ActionMenuItem>
        <ActionMenuItem>
          <EditIcon />
          <Link to={`/events/${selectedEvent?.id}/edit`}>
            {t('edit')}
          </Link>
        </ActionMenuItem>
        <ActionMenuItem>
          <DeleteIcon />
          <Link to={`/events/${selectedEvent?.id}/delete`}>{t('delete')}</Link>
        </ActionMenuItem>
      </BottomMenu>

    </PageContent>
  )
}
