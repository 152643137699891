import { gql } from '@apollo/client';
import {LimitedUser} from './users';
import {Device} from './devices';

export interface Visibility {
  id: number,
  visibility: string
}

export interface Unit {
  id: number
  parentId: number
  visibilities: Visibility[]
  children?: Unit[]
  user: LimitedUser
  sidc: string
  name: string
  additionalInfo: string
  joinSecret: string
  device: Device
}

export interface CreateUnit {
  eventId: number
  factionId: number
  parentId: number
  sidc: string
  name: string
  additionalInfo: string
}

export interface DeleteUnit {
  eventId: number
  factionId: number
  id: number
}

export interface UpdateUnit {
  id: number
  eventId: number
  factionId: number
  parentId: number
  sidc: string
  name: string
  additionalInfo: string
}

export interface AdminUnitsData {
  adminGetUnits: Unit[]
}

export interface AdminUnitData {
  adminGetUnit: Unit
}

const UnitQuery =`
  id
  parentId
  visibilities {
    id
    visibility
  }
  user {
    id
    name
  }
  device {
    id
    name
    lastUpdate
  }
  sidc
  name
  additionalInfo
  joinSecret
`


const ADMIN_GET_UNITS_QUERY = gql`
  query AdminGetUnits($eventId: ID!, $factionId: ID!) {
    adminGetUnits(eventId: $eventId, factionId: $factionId) { 
      ${UnitQuery}
    }
  } 
  `;

const ADMIN_GET_UNIT_QUERY = gql`
  query AdminGetUnit($eventId: ID!, $factionId: ID!, $id: ID!) {
    adminGetUnit(eventId: $eventId, factionId: $factionId, id: $id) {
      ${UnitQuery}
    }
  }
`

const ADMIN_CREATE_UNIT_MUTATION = gql`
  mutation AdminCreateUnit($eventId: ID!, $factionId: ID!, $parentId: Int, $sidc: String!, $name: String!, $additionalInfo: String) {
    adminCreateUnit(eventId: $eventId, factionId: $factionId, parentId: $parentId, sidc: $sidc, name: $name, additionalInfo: $additionalInfo) { 
      ${UnitQuery}
    }
  } 
  `;

const ADMIN_UPDATE_UNIT_MUTATION = gql`
  mutation AdminUpdateUnit($eventId: ID!, $factionId: ID!, $id: ID!, $parentId: Int, $sidc: String!, $name: String!, $additionalInfo: String) {
    adminUpdateUnit(eventId: $eventId, factionId: $factionId, id: $id, parentId: $parentId, sidc: $sidc, name: $name, additionalInfo: $additionalInfo) { 
      ${UnitQuery}
    }
  } 
  `;

const ADMIN_DELETE_UNIT_MUTATION = gql`
  mutation AdminDeleteUnit($eventId: ID!, $factionId: ID!, $id: ID!) {
    adminDeleteUnit(eventId: $eventId, factionId: $factionId, id: $id) { 
      ${UnitQuery}
    }
  } 
  `;

const CREATE_SUB_UNIT_MUTATION = gql`
  mutation CreateSubUnit($parentId: Int!, $sidc: String!, $name: String!, $additionalInfo: String) {
    createSubUnit(parentId: $parentId, sidc: $sidc, name: $name, additionalInfo: $additionalInfo) { 
      ${UnitQuery}   
    }
  } 
  `;

const UPDATE_SUB_UNIT_MUTATION = gql`
  mutation UpdateSubUnit($id: ID!, $parentId: Int!, $sidc: String!, $name: String!, $additionalInfo: String) {
    updateSubUnit(id: $id, parentId: $parentId, sidc: $sidc, name: $name, additionalInfo: $additionalInfo) { 
      ${UnitQuery}
    }
  } 
  `;

const DELETE_SUB_UNIT_MUTATION = gql`
  mutation DeleteSubUnit($id: ID!) {
    deleteSubUnit(id: $id) { 
      ${UnitQuery}
    }
  } 
  `;

const sortByNameAsc = (a, b) => {
  const nameA = a.name.toUpperCase(); // ignore upper and lowercase
  const nameB = b.name.toUpperCase(); // ignore upper and lowercase
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  // names must be equal
  return 0;
};

const createUpdateCache = (eventId, factionId, client, result) => {
  const { adminCreateUnit } = result.data;
  const factionUnitsData = client.readQuery({
    query: ADMIN_GET_UNITS_QUERY,
    variables: {
      eventId,
      factionId
    }
  });
  const newFactionUnitsData = {
    adminGetUnits: [...factionUnitsData.adminGetUnits, adminCreateUnit]
      .sort(sortByNameAsc)
  }
  client.writeQuery({
    query: ADMIN_GET_UNITS_QUERY,
    data: newFactionUnitsData,
    variables: {
      eventId,
      factionId
    }
  });
}

const updateUpdateCache = (eventId, factionId, client, result) => {
  const { adminUpdateUnit } = result.data;
  const data = client.readQuery({
    query: ADMIN_GET_UNITS_QUERY,
    variables: {
      eventId,
      factionId
    }
  });
  const newData = {
    adminGetUnits: data.adminGetUnits
      .map(unit => unit.id === adminUpdateUnit.id ? adminUpdateUnit : unit)
      .sort(sortByNameAsc)
  }
  client.writeQuery({
    query: ADMIN_GET_UNITS_QUERY,
    data: newData,
    variables: {
      eventId,
      factionId
    }
  });
}

const deleteUpdateCache = (eventId, factionId, client, result) => {
  const { adminDeleteUnit } = result.data;
  const data = client.readQuery({
    query: ADMIN_GET_UNITS_QUERY,
    variables: {
      eventId,
      factionId
    }
  });
  const newData = {
    adminGetUnits: data.adminGetUnits
      .filter(unit => unit.id !== adminDeleteUnit.id)
      .sort(sortByNameAsc)
  }
  client.writeQuery({
    query: ADMIN_GET_UNITS_QUERY,
    data: newData,
    variables: {
      eventId,
      factionId
    }
  });
}

const setUnitChildren = (unit: Unit, allUnits: Unit[]): Unit => {
  return {
    ...unit,
    children: allUnits
      .filter(u => u.parentId === parseInt(unit.id+''))
      .map(unit => setUnitChildren(unit, allUnits))
  }
}

const unitsToTree = (units: Unit[]): Unit[] => {
  return units
    .filter(unit => !unit.parentId)
    .map(rootUnit => setUnitChildren(rootUnit, units));
}

const isChild = (parent: Unit, unit: Unit) => {
  return parent.children?.find(child => child.id === unit.id || isChild(child, unit))
}

export {
  ADMIN_GET_UNITS_QUERY,
  ADMIN_CREATE_UNIT_MUTATION,
  ADMIN_UPDATE_UNIT_MUTATION,
  ADMIN_DELETE_UNIT_MUTATION,
  ADMIN_GET_UNIT_QUERY,
  CREATE_SUB_UNIT_MUTATION,
  UPDATE_SUB_UNIT_MUTATION,
  DELETE_SUB_UNIT_MUTATION,
  unitsToTree,
  isChild,
  createUpdateCache,
  updateUpdateCache,
  deleteUpdateCache
}
