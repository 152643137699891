import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import {
  ADMIN_GET_EVENT_QUERY, ADMIN_UPDATE_EVENT_MUTATION,
  AdminEventData,
  Event,
  EventArea,
  EventAreaLayer,
  updateUpdateCache
} from '../graphql/events';
import { Map } from './Map'
import { LayersEditor } from './LayersEditor';
import { center, FeatureCollection as TurfFeatureCollection } from '@turf/turf'
import dayjs from 'dayjs';

const MapEditorContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
`
const FINLAND_CENTER_LON_LAT = [25.7482, 61.9241]

const featuresToFeatureCollection = (features: object): TurfFeatureCollection => {
  return {
    type: 'FeatureCollection',
    features: Object.keys(features).map(key => {
      return features[key]
    })
  }
}

export const MapEditor = () => {
  const { eventId: id, layerId } = useParams();
  const [area, setArea] = useState<EventArea | null>(null)
  const [adminUpdateEvent] = useMutation(ADMIN_UPDATE_EVENT_MUTATION);
  const selectedLayer = area?.areaLayers.find(layer => layer.id === layerId)

  const onEventLoaded =({ adminGetEvent: event }) => {
    if(event.area) {
      const parsedEventArea = JSON.parse(event.area) as EventArea
      setArea(parsedEventArea)
    } else {
      setArea({
        centerLonLat: FINLAND_CENTER_LON_LAT,
        areaLayers: [],
        savedTimestamp: 0
      })
    }
  }

  const { loading: getEventLoading, data: getEventData } = useQuery<AdminEventData>(ADMIN_GET_EVENT_QUERY, {
    variables: { id },
    onCompleted: onEventLoaded
  });

  const event = getEventData?.adminGetEvent;

  const updateEventAreaLayersCallback = useCallback((layers: EventAreaLayer[]): Promise<any> => {
    if(event && area) {
      const updateEvent: Event = {
        ...event,
        area: JSON.stringify({
          ...area,
          areaLayers: layers,
          savedTimestamp: dayjs().unix()
        })
      }
      return adminUpdateEvent({
        variables: updateEvent,
        update: updateUpdateCache
      })
    }
    return Promise.reject('Event not loaded')
  }, [event, area, adminUpdateEvent])

  const updateEventAreaLayer = useCallback((features: object, updateLayerId: string): Promise<any> => {
    if(event && area && layerId) {
      const layerFeatureCollection = featuresToFeatureCollection(features)
      const layers = area.areaLayers
      const oldLayer: EventAreaLayer = area.areaLayers.find(layer => layer.id === updateLayerId)!
      const layerIndex = layers.indexOf(oldLayer!)
      layers[layerIndex] = {
        ...oldLayer,
        featureCollection: {
          type: 'FeatureCollection',
          features: Object.keys(features).map(key => {
            return features[key]
          })
        }
      }

      const newArea: EventArea = {
        centerLonLat: layerFeatureCollection.features.length > 0 ?
          center(layerFeatureCollection).geometry.coordinates :
          area.centerLonLat
        ,
        areaLayers: layers,
        savedTimestamp: dayjs().unix()
      }

      const updateEvent: Event = {
        ...event,
        area: JSON.stringify(newArea)
      }
      return adminUpdateEvent({
        variables: updateEvent,
        update: updateUpdateCache
      })
    }
    return Promise.reject('Event not loaded')
  }, [event, area, layerId, adminUpdateEvent])

  return !getEventLoading ? (
    <MapEditorContainer>
      <LayersEditor
        areaLayers={area?.areaLayers || []}
        updateEventAreaLayersCallback={updateEventAreaLayersCallback}
      />
      <Map
        layer={selectedLayer}
        allLayers={area?.areaLayers || []}
        layerUpdateCallback={updateEventAreaLayer}
      />
    </MapEditorContainer>
  ): null
}
