import { gql } from '@apollo/client';

export const GET_BODY_HEIGHT = gql`
  query GetBodyHeight {
    bodyHeight @client
  }
`;

export const GET_BODY_WIDTH = gql`
  query GetBodyWidth {
    bodyWidth @client
  }
`;

export const GET_MENU_OPEN = gql`
  query GetMenuOpen {
    menuOpen @client
  }
`;
