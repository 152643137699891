import React from 'react';

import {PrivateRoute} from '../utils/PrivateRoute';
import {FactionsPage} from './FactionsPage';
import {NewFactionPage} from './NewFactionPage';
import {DeleteFactionPage} from './DeleteFactionPage';
import {EditFactionPage} from './EditFactionPage';
import {Units} from '../units/Units';

export const Factions = () => {
  return (
    <>
      <PrivateRoute exact requiredGroup={'admin'} path="/events/:eventId/factions">
        <FactionsPage />
      </PrivateRoute>
      <PrivateRoute requiredGroup={'admin'} path="/events/:eventId/factions/new">
        <NewFactionPage />
      </PrivateRoute>
      <PrivateRoute requiredGroup={'admin'} path="/events/:eventId/factions/:factionId/delete">
        <DeleteFactionPage />
      </PrivateRoute>
      <PrivateRoute requiredGroup={'admin'} path="/events/:eventId/factions/:factionId/edit">
        <EditFactionPage />
      </PrivateRoute>
      <PrivateRoute requiredGroup={'admin'} path="/events/:eventId/factions/:factionId/units">
        <Units />
      </PrivateRoute>
    </>
  )
}
