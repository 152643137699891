import { gql } from '@apollo/client';
import { Faction } from './factions';
import { FeatureCollection } from 'geojson';

export interface LinePaintOptions {
  color: string
  opacity: number
  lineWidth: number
}

export enum LayerTypeOption {
  FILL='fill',
  LINE='line',
  SYMBOL='symbol',
  CIRCLE='circle'
}

export interface EventAreaLayer {
  id: string
  name: string
  type: LayerTypeOption
  maxzoom: number
  minzoom: number
  paintOptions: LinePaintOptions
  featureCollection?: FeatureCollection
  filter?: any[]
}

export interface EventArea {
  centerLonLat: number[]
  areaLayers: EventAreaLayer[]
  savedTimestamp: number
}

export interface Event {
  id: number
  name: string
  description: string
  area?: string
  factions?: Faction[]
  startTime: number
  endTime: number
}

export interface CreateEvent {
  name: string
  description: string
  startTime: number
  endTime: number
}

export interface AdminEventsData {
  adminGetEvents: Event[]
}

export interface AdminEventData {
  adminGetEvent: Event
}

const ADMIN_GET_EVENTS_QUERY = gql`
  query AdminGetEvents {
        adminGetEvents { 
          id
          name
          description
          area
          startTime
          endTime
          factions {
            id
            name
            description
          }
        }
      } 
  `;

const ADMIN_GET_EVENT_QUERY = gql`
  query AdminGetEvent($id: ID!) {
        adminGetEvent(id: $id) { 
          id
          name
          description
          area
          startTime
          endTime
          factions {
            id
            name
            description
          }
        }
      } 
  `;

const ADMIN_CREATE_EVENT_MUTATION = gql`
  mutation AdminCreateEvent($name: String!, $description: String, $startTime: String!, $endTime: String!) {
        adminCreateEvent(name: $name, description: $description, startTime: $startTime, endTime: $endTime) { 
          id
          name
          description
          startTime
          endTime
          factions {
            id
            name
            description
          }
        }
      } 
  `;

const ADMIN_UPDATE_EVENT_MUTATION = gql`
  mutation AdminUpdateEvent($id: ID!, $name: String!, $description: String, $area: String, $startTime: String!, $endTime: String!) {
        adminUpdateEvent(id: $id, name: $name, description: $description, area: $area, startTime: $startTime, endTime: $endTime) { 
          id
          name
          description
          area
          startTime
          endTime
          factions {
            id
            name
            description
          }
        }
      } 
  `;

const ADMIN_DELETE_EVENT_MUTATION = gql`
  mutation AdminDeleteEvent($id: ID!) {
        adminDeleteEvent(id: $id) { 
          id
          name
          description
          startTime
          endTime
          factions {
            id
            name
            description
          }
        }
      } 
  `;

const ADMIN_EVENT_UPDATES_SUBSCRIPTION = gql`
      subscription AdminEventUpdates {
        adminOnEventUpdates {
          id
          name
          description
          startTime
          endTime
        }
      }
    `;

const sortEventsStartTimeAsc = (a, b) => b.startTime - a.startTime;

const createUpdateCache = (client, result) => {
  const { adminCreateEvent } = result.data;
  const data = client.readQuery({
    query: ADMIN_GET_EVENTS_QUERY,
  });
  const newData = {
    adminGetEvents: [ adminCreateEvent, ...data.adminGetEvents]
      .sort(sortEventsStartTimeAsc)
  }
  client.writeQuery({
    query: ADMIN_GET_EVENTS_QUERY,
    data: newData
  });
}

const updateUpdateCache = (client, result) => {
  const { adminUpdateEvent } = result.data;
  const data = client.readQuery({
    query: ADMIN_GET_EVENTS_QUERY
  });
  const newData = {
    adminGetEvents: data.adminGetEvents
      .map(e => e.id === adminUpdateEvent.id ? adminUpdateEvent : e)
      .sort(sortEventsStartTimeAsc)
  }
  client.writeQuery({
    query: ADMIN_GET_EVENTS_QUERY,
    data: newData
  });
}

const deleteUpdateCache = (client, result) => {
  const { adminDeleteEvent } = result.data;
  const data = client.readQuery({
    query: ADMIN_GET_EVENTS_QUERY
  });
  const newData = {
    adminGetEvents: data.adminGetEvents.filter((t) => t.id !== adminDeleteEvent.id)
      .sort(sortEventsStartTimeAsc)
  }
  client.writeQuery({
    query: ADMIN_GET_EVENTS_QUERY,
    data: newData
  });
}

const adminEventUpdatesHandler = (prev, { subscriptionData }) => {
  if (!subscriptionData.data) return prev;
  const newEvent = subscriptionData.data.adminOnEventUpdates;

  const events = prev.adminGetEvents.map(event => event.id === newEvent.id ? newEvent : event);
  if(!events.find(event => event.id === newEvent.id)) {
    events.push(newEvent)
  };
  return Object.assign({}, prev, {
    adminGetEvents: events
  });
}

export {
  ADMIN_GET_EVENTS_QUERY,
  ADMIN_GET_EVENT_QUERY,
  ADMIN_CREATE_EVENT_MUTATION,
  ADMIN_UPDATE_EVENT_MUTATION,
  ADMIN_DELETE_EVENT_MUTATION,
  ADMIN_EVENT_UPDATES_SUBSCRIPTION,
  adminEventUpdatesHandler,
  createUpdateCache,
  deleteUpdateCache,
  updateUpdateCache
};