import { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import awsconfig from '../common/aws/aws-exports';
import Amplify from 'aws-amplify';
Amplify.configure(awsconfig);

function useUserGroups(): string[] {
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    Auth.currentSession()
      .then(session => {
        setGroups(session.getAccessToken().decodePayload()['cognito:groups'])
      })
  }, []);

  return groups;
}

function useUser() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    Auth.currentSession()
      .then(session => {
        //@ts-ignore
        setUser(session.getAccessToken().decodePayload())
      })
  }, []);

  return user;
}

export { useUser, useUserGroups };