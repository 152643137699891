import React from 'react';
import styled from 'styled-components';

interface IconButtonProps {
  clickHandler: any
  children: any
  size: 'lg' | 'sm'
};

interface IconButtonStyledProps {
  size: 'lg' | 'sm'
};

const IconButtonStyled = styled.button<IconButtonStyledProps>`
  user-select: none;
  outline: none;
  background: none;
  border: none;
  padding: 0;
  width: ${({ theme, size }) => size === 'lg' ? theme.spacing.default*2: theme.spacing.default*1.5}px;
  height: ${({ theme, size }) => size === 'lg' ? theme.spacing.default*2: theme.spacing.default*1.5}px;
  cursor: pointer;
`;

export const IconButton = (props: IconButtonProps) => {
  return (
    <IconButtonStyled size={props.size} onClick={props.clickHandler}>
      {props.children}
    </IconButtonStyled>
  )
}
