import React from 'react';
import styled from 'styled-components';
import threeDots from '../assets/three-dots.svg';

interface ThreeDotButtonProps {
  clickHandler: any
};

const ThreeDots = styled.img`
  margin: ${props => props.theme.spacing.margin};
`;

const ThreeDotButtonStyled = styled.button`
  user-select: none;
  outline: none;
  background: none;
  border: none;
  padding: 0;
  width: 26px;
  height: 26px;
`;

export const ThreeDotButton = (props: ThreeDotButtonProps) => {
  return (
    <ThreeDotButtonStyled onClick={props.clickHandler}>
      <ThreeDots src={threeDots} />
    </ThreeDotButtonStyled>
  )
}
