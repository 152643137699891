import {InMemoryCache, makeVar} from '@apollo/client';

export const menuOpenVar = makeVar(false);
export const bodyWidthVar = makeVar(0);
export const bodyHeightVar = makeVar(0);

export const cache = new InMemoryCache({
  typePolicies: {
    Event: {
      fields: {
        factions: {
          merge(existing = [], incoming: any[]) {
            return incoming;
          }
        }
      }
    },
    Unit: {
      fields: {
        device: {
          merge(existing = [], incoming: any[]) {
            return incoming;
          }
        }
      }
    },
    Query: {
      fields: {
        menuOpen: {
          read() {
            return menuOpenVar();
          }
        },
        bodyWidth: {
          read() {
            return bodyWidthVar();
          }
        },
        bodyHeight: {
          read() {
            return bodyHeightVar();
          }
        },
        adminGetEvent(_, { args, toReference }) {
          return toReference({
            __typename: 'Event',
            id: args?.id,
          });
        },
        adminGetFaction(_, { args, toReference }) {
          return toReference({
            __typename: 'Faction',
            id: args?.id,
          });
        },
        adminGetUnit(_, { args, toReference }) {
          return toReference({
            __typename: 'Unit',
            id: args?.id,
          });
        },
        adminGetEvents: {
          merge(existing = [], incoming: any[]) {
            return incoming;
          }
        },
        adminGetUnits: {
          merge(existing = [], incoming: any[]) {
            return incoming;
          }
        },
        adminGetAvailableDevices: {
          merge(existing = [], incoming: any[]) {
            return incoming;
          }
        }
    }
    }
  }
});
