import { Auth } from 'aws-amplify';
import awsconfig from './aws-exports';
import Amplify from 'aws-amplify';

import { ApolloClient, ApolloLink } from '@apollo/client';
import { createSubscriptionHandshakeLink } from "aws-appsync-subscription-link";
import { createAuthLink } from "aws-appsync-auth-link";
import { cache } from '../../graphql/cache'

Amplify.configure(awsconfig)

const getAccessToken = () => {
  return Auth.currentSession().then(session => {
    return session.getAccessToken().getJwtToken();
  });
};

const config = {
  url: awsconfig.aws_appsync_endpoint,
  region: awsconfig.aws_project_region,
  auth: {
    type: 'AMAZON_COGNITO_USER_POOLS',
    jwtToken: getAccessToken, // Required when you use Cognito UserPools OR OpenID Connect. token object is obtained previously
  },
  disableOffline: true
};

const link = ApolloLink.from([
  createAuthLink(config),
  createSubscriptionHandshakeLink(config)
]);

export const client = new ApolloClient({
  link,
  cache
});
