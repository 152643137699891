import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory, Link } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import { PageContent } from '../components/PageContent';
import {useMutation, useQuery} from '@apollo/client';
import { useOnClickOutside } from '../hooks';

import {
  Unit,
  AdminUnitsData,
  ADMIN_GET_UNITS_QUERY, unitsToTree
} from '../graphql/units';
import {
  ADMIN_GET_FACTION_QUERY,
  AdminFactionData
} from '../graphql/factions';
import {
  ADMIN_ATTACH_DEVICE_TO_UNIT_MUTATION,
  ADMIN_DETACH_DEVICE_FROM_UNIT_MUTATION,
  AdminDetachDeviceFromUnitData,
  AdminAttachDeviceToUnitData,
  detachUpdateCache,
  attachUpdateCache
} from '../graphql/devices';

import { BottomMenu } from '../components/BottomMenu';
import { ActionMenuItem } from '../components/ActionMenuItem';
import { Button } from '../components/Button';

import {AddIcon, DeleteIcon, EditIcon, LockIcon} from '../components/icons';
import {ContentPageHeading} from '../components/ContentPageHeading';
import { Orbat } from '../components/Orbat';
import {ADMIN_GET_AVAILABLE_DEVICES, AdminAvailableDevicesData, Device} from '../graphql/devices';
import {DeviceSelector} from '../components/DeviceSelector';
import styled from 'styled-components';

const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;


const unitToCsvRow = (prefix: string, unit: Unit) => {
  const name = `${prefix}${unit.name}`
  const unitRow = [name, unit.joinSecret]

  const childRows = unit.children?.map(child => unitToCsvRow(`${name} > `, child))
    .reduce((prev, cur) => {
      return prev.concat(cur)
    }, []) || [];
  return [unitRow, ...childRows];
}

export const UnitsPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { eventId, factionId } = useParams();

  const [selectedUnit, setSelectedUnit] = useState<Unit>();
  const [csvData, setCsvData] = useState([]);
  const [bottomMenuOpen, setBottomMenuOpen] = useState<boolean>(false);
  const node = useRef() as React.MutableRefObject<HTMLDivElement>;

  const unitsLoaded = (data: AdminUnitsData) => {
    const unitTree = unitsToTree(data.adminGetUnits)
    const csvRows = unitTree
      .map(unit => unitToCsvRow('', unit))
      .reduce((prev, cur) => {
        return prev.concat(cur);
      },[['Unit name', 'Join code']]);
    setCsvData(csvRows)
  }

  const { loading: factionLoading, data: factionsData } = useQuery<AdminFactionData>(ADMIN_GET_FACTION_QUERY, {
    variables: { eventId, id: factionId }
  });
  const { loading: unitsLoading, data: unitsData } = useQuery<AdminUnitsData>(ADMIN_GET_UNITS_QUERY, {
    variables: { eventId, factionId },
    onCompleted: unitsLoaded
  });
  const { loading: availableDevicesLoading, data: availableDevices } = useQuery<AdminAvailableDevicesData>(ADMIN_GET_AVAILABLE_DEVICES, {
    variables: { eventId }
  });
  const [adminDetachDevice, { loading: detaching }] = useMutation<AdminDetachDeviceFromUnitData>(ADMIN_DETACH_DEVICE_FROM_UNIT_MUTATION);
  const [adminAttachDevice, { loading: attaching }] = useMutation<AdminAttachDeviceToUnitData>(ADMIN_ATTACH_DEVICE_TO_UNIT_MUTATION);

  const faction = factionsData?.adminGetFaction;
  const loading = factionLoading || unitsLoading

  const onUnitClick = unit => {
    setSelectedUnit(unit);
    setBottomMenuOpen(true);
  }
  const closeHandler = bottomMenuOpen => {
    if(bottomMenuOpen) {
      setSelectedUnit(undefined);
      setBottomMenuOpen(false);
    }
  }

  const onDeviceSelected = event => {
    adminAttachDevice({
      variables: {
        eventId,
        factionId,
        unitId: selectedUnit?.id,
        deviceId: event.target.value
      },
      update: (client, result) => attachUpdateCache(eventId, factionId, selectedUnit?.id, client, result)
    }).then(() => {
      setSelectedUnit(undefined);
      setBottomMenuOpen(false);
    })
  }

  const removeDeviceHandler = (device: Device) => {
    adminDetachDevice({
      variables: {
        eventId,
        factionId,
        unitId: selectedUnit?.id,
        deviceId: device.id
      },
      update: (client, result) => detachUpdateCache(eventId, factionId, selectedUnit?.id, client, result)
    }).then(() => {
      setSelectedUnit(undefined);
      setBottomMenuOpen(false);
    })
  }

  useOnClickOutside(node, () => closeHandler(bottomMenuOpen));

  return (
    <PageContent loading={loading || attaching || detaching || availableDevicesLoading} heading={t('units.pageHeader')}>
      <ContentPageHeading backClickHandler={() => history.push(`/events/${eventId}/factions`)} heading={faction?.name as string}/>
      <h2>{t('units.pageHeader')}</h2>
      <ButtonsContainer>
        <Button size={'lg'} clickHandler={() => history.push(`/events/${eventId}/factions/${factionId}/units/new`)}>
          <AddIcon />
          <span>{t('units.newUnit')}</span>
        </Button>
        <Button clickHandler={()=>{}} size={'lg'}>
          <CSVLink
            data={csvData}
            filename={`${faction?.name}-units.csv`}
          >ORBAT CSV</CSVLink>
        </Button>
      </ButtonsContainer>

      { unitsData?.adminGetUnits && <Orbat onUnitClick={onUnitClick} units={unitsData?.adminGetUnits}/> }

      <BottomMenu
        refNode={node}
        closeHandler={closeHandler}
        heading={selectedUnit ? `${selectedUnit.name}, ${selectedUnit.additionalInfo}` : ''}
        open={bottomMenuOpen}
      >
        <ActionMenuItem>
          <DeviceSelector
            device={selectedUnit?.device}
            availableDevices={availableDevices?.adminGetAvailableDevices || []}
            onDeviceChange={onDeviceSelected}
            onRemove={removeDeviceHandler}
            trackerText={t('tracker')}
            selectTrackerText={t('selectTracker')}
          />
        </ActionMenuItem>
        <ActionMenuItem>
          <AddIcon />
          <Link to={`/events/${eventId}/factions/${factionId}/units/new?parentId=${selectedUnit?.id}`}>
            {t('units.newChild')}
          </Link>
        </ActionMenuItem>
        <ActionMenuItem>
          <EditIcon />
          <Link to={`/events/${eventId}/factions/${factionId}/units/${selectedUnit?.id}/edit`}>
            {t('edit')}
          </Link>
        </ActionMenuItem>
        <ActionMenuItem>
          <DeleteIcon />
          <Link to={`/events/${eventId}/factions/${factionId}/units/${selectedUnit?.id}/delete`}>{t('delete')}</Link>
        </ActionMenuItem>
        <ActionMenuItem>
          <LockIcon/>
          <span>{t('units.joinSecret')}: {selectedUnit?.joinSecret}</span>
        </ActionMenuItem>
      </BottomMenu>
    </PageContent>
  )
}
