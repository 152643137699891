import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../components/Button';
import { EventAreaContext } from './EventAreaProvider';

export const MapModeButton = () => {
  const { t } = useTranslation();
  const { mapMode, setMapMode } = useContext(EventAreaContext)

  const mapModeClick = useCallback(() => {
    setMapMode(mapMode === 'draw' ? 'style' : 'draw')
  }, [mapMode, setMapMode])

  return (
    <Button size={'lg'} clickHandler={mapModeClick}>
      <span>{mapMode === 'draw' ? t(`eventsArea.style`) : t(`eventsArea.draw`)}</span>
    </Button>
  )
}
