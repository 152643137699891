import { gql } from '@apollo/client';
import {ADMIN_GET_UNITS_QUERY} from './units';

export interface Device {
  id: number
  name: string
  attributes: any
  lastUpdate: number
  status: string
}

export interface AdminAvailableDevicesData {
  adminGetAvailableDevices: Device[]
}

export interface AdminAttachDeviceToUnitData {
  adminAttachDeviceToUnit: Device
}

export interface AdminDetachDeviceFromUnitData {
  adminDetachDeviceFromUnit: Device
}

const DeviceQuery = `
  id
  name
  lastUpdate
`

const ADMIN_GET_AVAILABLE_DEVICES = gql`
  query AdminGetAvailableDevices($eventId: ID!) {
    adminGetAvailableDevices(eventId: $eventId) { 
      ${DeviceQuery}      
    }
  } 
  `;

const GET_DEVICE_POSITIONS = gql`
    query GetDevicePositions($deviceId: ID!) {
      getDevicePositions(deviceId: $deviceId) {
        id
        deviceId
        attributes 
        altitude
        latitude
        longitude
      }
  } 
  `;

const DEVICE_POSITIONS_SUBSCRIPTION = gql`
  subscription OnNewDevicePosition($deviceId: ID!) {
    onNewDevicePosition(deviceId: $deviceId) {
      id
      deviceId
      attributes 
      altitude
      latitude
      longitude
    }
  } 
  `;

const ADMIN_ATTACH_DEVICE_TO_UNIT_MUTATION = gql`
  mutation AdminAttachDeviceToUnit($eventId: ID!, $factionId: ID!, $unitId: ID!, $deviceId: ID!) {
    adminAttachDeviceToUnit(eventId: $eventId, factionId: $factionId, unitId: $unitId, deviceId: $deviceId) { 
      ${DeviceQuery}
    }
  } 
  `;

const ADMIN_DETACH_DEVICE_FROM_UNIT_MUTATION = gql`
  mutation AdminDetachDeviceFromUnit($eventId: ID!, $factionId: ID!, $unitId: ID!, $deviceId: ID!) {
    adminDetachDeviceFromUnit(eventId: $eventId, factionId: $factionId, unitId: $unitId, deviceId: $deviceId) { 
      ${DeviceQuery}
    }
  }
  `;

const updateUnitDeviceCache = (client, eventId, factionId, unitId, device) => {
  const factionUnitsData = client.readQuery({
    query: ADMIN_GET_UNITS_QUERY,
    variables: {
      eventId,
      factionId
    }
  });
  const newFactionUnitsData = {
    adminGetUnits: factionUnitsData.adminGetUnits
      .map(unit => {
        if(unit.id === unitId) {
          return {
            ...unit,
            device
          }
        }
        return unit;
      })
  }
  client.writeQuery({
    query: ADMIN_GET_UNITS_QUERY,
    data: newFactionUnitsData,
    variables: {
      eventId,
      factionId
    }
  });
}

const detachUpdateCache = (eventId, factionId, unitId, client, result) => {
  const { adminDetachDeviceFromUnit }: AdminDetachDeviceFromUnitData = result.data;
  const availableDevicesData = client.readQuery({
    query: ADMIN_GET_AVAILABLE_DEVICES,
    variables: {
      eventId
    }
  });
  const newAvailabeDevicesData = {
    adminGetAvailableDevices: [ ...availableDevicesData.adminGetAvailableDevices, adminDetachDeviceFromUnit]
      .sort()
  }
  client.writeQuery({
    query: ADMIN_GET_AVAILABLE_DEVICES,
    variables: {
      eventId
    },
    data: newAvailabeDevicesData
  });
  updateUnitDeviceCache(client, eventId, factionId, unitId, null);
}

const attachUpdateCache = (eventId, factionId, unitId, client, result) => {
  const { adminAttachDeviceToUnit }: AdminAttachDeviceToUnitData = result.data;
  const data = client.readQuery({
    query: ADMIN_GET_AVAILABLE_DEVICES,
    variables: {
      eventId
    }
  });
  const newAvailabeDevicesData = {
    adminGetAvailableDevices: data.adminGetAvailableDevices
      .filter(device => device.id !== adminAttachDeviceToUnit.id)
      .sort()
  }
  client.writeQuery({
    query: ADMIN_GET_AVAILABLE_DEVICES,
    variables: {
      eventId
    },
    data: newAvailabeDevicesData
  });
  updateUnitDeviceCache(client, eventId, factionId, unitId, adminAttachDeviceToUnit);
}

const newDevicePositionsHandler = (prev, { subscriptionData }) => {
  if (!subscriptionData.data) return prev;
  const newEvent = subscriptionData.data.onNewDevicePosition;
  return Object.assign({}, prev, {
    getDevicePositions: [ newEvent, ...prev.getDevicePositions.slice(0, 2) ]
  });
}

export {
  GET_DEVICE_POSITIONS,
  DEVICE_POSITIONS_SUBSCRIPTION,
  ADMIN_GET_AVAILABLE_DEVICES,
  ADMIN_ATTACH_DEVICE_TO_UNIT_MUTATION,
  ADMIN_DETACH_DEVICE_FROM_UNIT_MUTATION,
  newDevicePositionsHandler,
  detachUpdateCache,
  attachUpdateCache
}