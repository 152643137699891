import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash/debounce'

import { EventAreaLayer, LayerTypeOption } from '../graphql/events';
import { Label } from '../components/Label';
import { EventAreaContext } from './EventAreaProvider';
import { ColorPickerButton } from '../components/ColorPickerButton';
import { DeleteIcon } from '../components/icons';
import { IconButton } from '../components/IconButton';

const LayerEditFormStyled = styled.li`
  display: flex;
  flex-direction: column;
  list-style: none;
  border: 1px solid #fff;
  border-radius: 3px;
  margin: ${props => props.theme.spacing.margin}px 0;
  padding: ${props => props.theme.spacing.margin}px;
  position: relative;
  
  input[type='range']{
    width: 100%
  }
`

const LayerName = styled.p`
  color: ${props => props.theme.colors.main};
  margin: 0;
`

const DeleteContainer = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
`

interface LayerEditFormProps {
  layer: EventAreaLayer
  saveCallback: (layer: EventAreaLayer) => Promise<any>
  onDeleteClick: (e: any) => void
}

export const LayerEditForm = (props: LayerEditFormProps) => {
  const { t } = useTranslation();
  const { mapMode } = useContext(EventAreaContext)
  const { layer, saveCallback, onDeleteClick } = props
  const [name, setName] = useState<string>(layer.name)
  const [type, setType] = useState<LayerTypeOption>(layer.type)
  const [minzoom, setMinzoom] = useState<number>(Number.parseFloat(`${layer.minzoom}`))
  const [maxzoom, setMaxzoom] = useState<number>(Number.parseFloat(`${layer.maxzoom}`))
  const [color, setColor] = useState<string>(layer.paintOptions.color)
  const [opacity, setOpacity] = useState<number>(Number.parseFloat(`${layer.paintOptions.opacity}`)*100)
  const [lineWidth, setLineWidth] = useState<number>(Number.parseInt(`${layer.paintOptions.lineWidth}`))

  const [dirty, setDirty] = useState<boolean>(false)

  const saveLayer = useCallback((updateLayer: EventAreaLayer) => {
    saveCallback(updateLayer)
      .then(() => setDirty(false))
  }, [saveCallback])

  const featuresSaveHandler = useMemo(
    () => debounce(saveLayer, 2000)
    , [saveLayer]);

  const nameOnChange = useCallback((e): void => {
    const { value } = e.target
    setName(value)
    setDirty(true)
    featuresSaveHandler({
      ...layer,
      name: value,
      type
    })
  },[type, layer, featuresSaveHandler])

  const typeOnChange = useCallback((e): void => {
    const { value } = e.target
    setType(value)
    setDirty(true)
    featuresSaveHandler({
      ...layer,
      name,
      type: value
    })
  }, [name, layer, featuresSaveHandler])

  const minzoomOnChange = useCallback((e): void => {
    const { value } = e.target
    setMinzoom(value)
    setDirty(true)
    featuresSaveHandler({
      ...layer,
      minzoom: value,
      maxzoom,
      paintOptions: {
        color,
        opacity: opacity / 100,
        lineWidth
      }
    })
  }, [maxzoom, color, opacity, lineWidth, layer, featuresSaveHandler])

  const maxzoomOnChange = useCallback((e): void => {
    const { value } = e.target
    setMaxzoom(value)
    setDirty(true)
    featuresSaveHandler({
      ...layer,
      minzoom,
      maxzoom: value,
      paintOptions: {
        color,
        opacity: opacity / 100,
        lineWidth
      }
    })
  }, [minzoom, color, opacity, lineWidth, layer, featuresSaveHandler])

  const colorOnChange = useCallback((value): void => {
    setColor(value)
    setDirty(true)
    featuresSaveHandler({
      ...layer,
      paintOptions: {
        color: value,
        opacity: opacity / 100,
        lineWidth
      }
    })
  }, [opacity, lineWidth, layer, featuresSaveHandler])

  const opacityOnChange = useCallback((e): void => {
    const { value } = e.target
    setOpacity(value)
    setDirty(true)
    featuresSaveHandler({
      ...layer,
      paintOptions: {
        color,
        opacity: value / 100,
        lineWidth
      }
    })
  }, [color, lineWidth, layer, featuresSaveHandler])

  const lineWidthOnChange = useCallback((e): void => {
    const { value } = e.target
    setLineWidth(value)
    setDirty(true)
    featuresSaveHandler({
      ...layer,
      paintOptions: {
        color,
        opacity: opacity / 100,
        lineWidth: value
      }
    })
  }, [color, opacity, layer, featuresSaveHandler])

  useEffect(() => {
    return () => {
      featuresSaveHandler.cancel()
    }
  }, [featuresSaveHandler])

  return (
    <LayerEditFormStyled>
      <DeleteContainer>
        <IconButton clickHandler={onDeleteClick} size={'sm'}>
          <DeleteIcon />
        </IconButton>
      </DeleteContainer>
      <LayerName>{`${name}${dirty ? '*' : ''}`}</LayerName>

      {mapMode === 'draw' ? (
        <form>
          <Label htmlFor={'name'} hasError={'false'}>
            {t('eventsArea.layerName')}
          </Label>
          <input type="text" id="name" name="name" placeholder={t('eventsArea.layerName')} onChange={nameOnChange} value={name}/>
          <Label htmlFor={'name'} hasError={'false'}>
            {t('eventsArea.layerType')}
          </Label>
          <select value={type} name={'layerType'} onChange={typeOnChange}>
            <option id={LayerTypeOption.LINE} value={LayerTypeOption.LINE}>{LayerTypeOption.LINE}</option>
            <option id={LayerTypeOption.FILL} value={LayerTypeOption.FILL}>{LayerTypeOption.FILL}</option>
          </select>
        </form>
      ): (
        <form>
          <Label htmlFor={'color'} hasError={'false'}>
            {t('color')}
          </Label>
          <ColorPickerButton onChangeCallback={colorOnChange} initialColor={color} />
          <Label htmlFor={'opacity'} hasError={'false'}>
            {t('opacity')}{` ${opacity}%`}
          </Label>
          <input type="range" id="opacity" name="opacity" min="10" max="100" value={opacity} onChange={opacityOnChange} />

          {layer.type === LayerTypeOption.LINE && (
            <>
              <Label htmlFor={'lineWidth'} hasError={'false'}>
                {t('lineWidth')}{` ${lineWidth}px`}
              </Label>
              <input type="range" id="lineWidth" min="1" max="20" value={lineWidth} name={'lineWidth'} onChange={lineWidthOnChange} />
            </>
          )}
          <Label htmlFor={'minzoom'} hasError={'false'}>
            {t('eventsArea.minZoom')}{` ${minzoom}`}
          </Label>
          <input type="range" min={0} max={20} id="minzoom" name="minzoom" onChange={minzoomOnChange} value={minzoom}/>
          <Label htmlFor={'maxzoom'} hasError={maxzoom < minzoom ? 'true' : 'false'}>
            {t('eventsArea.maxZoom')}{` ${maxzoom}`}
          </Label>
          <input type="range" min={0} max={20} id="maxzoom" name="maxzoom" onChange={maxzoomOnChange} value={maxzoom}/>
        </form>
      )}

    </LayerEditFormStyled>
  )
}
