import React from 'react';
import styled from 'styled-components';
import { FullPage } from '../components/FullPage';
import { useParams, useHistory } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { Delete } from '@styled-icons/material/Delete';

import { useTranslation } from 'react-i18next';
import {Button} from '../components/Button';
import {
  ADMIN_GET_FACTION_QUERY,
  ADMIN_DELETE_FACTION_MUTATION,
  AdminFactionData,
  deleteUpdateCache
} from '../graphql/factions';

const StyledDeletePage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const DeleteIcon = styled(Delete)`
  color: ${props => props.theme.colors.main};
  height: ${props => props.theme.spacing.default*2}px;
  width: ${props => props.theme.spacing.default*2}px;
`

export const DeleteFactionPage = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { eventId, factionId: id } = useParams();
  const { loading: getFactionLoading, data: getFactionData } = useQuery<AdminFactionData>(ADMIN_GET_FACTION_QUERY, { variables: { id }});

  const [adminDeleteFaction, { loading: deleting }] = useMutation(ADMIN_DELETE_FACTION_MUTATION);

  const onDelete = () => {
    adminDeleteFaction({
      variables: { eventId, id },
      update: (client, result) => deleteUpdateCache(eventId, client, result)
    })
      .then(() => {
        history.push(`/events/${eventId}/factions`)
      })
  }

  return (
    <FullPage
      heading={deleting ? t('delete'): getFactionData?.adminGetFaction.name as string}
      loading={deleting || getFactionLoading}
      closeHandler={() => history.push(`/events/${eventId}/factions`)}
    >
      <StyledDeletePage>
        <h2>{t('delete')} {getFactionData?.adminGetFaction.name}?</h2>
        <Button clickHandler={onDelete} size={'lg'}><DeleteIcon /> {t('delete')}</Button>
      </StyledDeletePage>
    </FullPage>
  )
}
