import ms from 'milsymbol';

const Affiliation = {
  UNKNOWN: 'U',
  FRIENDLY: 'F',
  NEUTRAL: 'N',
  HOSTILE: 'H'
}

const UnitSize = {
  '-': 'UNDEFINED',
  'A': 'TEAM',
  'B': 'SQUAD',
  'C': 'SECTION',
  'D': 'PLATOON',
  'E': 'COMPANY',
  'F': 'BATTALION',
  'G': 'REGIMENT'
}

const FunctionId = {
  'U': 'UNDEFINED',
  'UCD': 'AIR_DEFENCE',
  'UCAA': 'ANTI_TANK',
  'UCA': 'ARMOUR',
  'UCF': 'FIELD_ARTILLERY',
  'UCE': 'ENGINEER',
  'UCI': 'INFANTRY',
  'UCIZ': 'MECHANIZED_INFANTRY',
  'UCIM': 'MOTORIZED_INFANTRY',
  'USM': 'MEDICAL',
  'UCR': 'RECONNAISSANCE',
  'USS': 'SUPPLY',
  'UST': 'TRANSPORTATION'
}

const unitSizeOptions = (t: any) => {
  return Object.keys(UnitSize)
    .map(key => {
      return {
        id: key,
        name: t(`units.size.${key}`)
      }
    })
}

const functionIdOptions = (t: any) => {
  return Object.keys(FunctionId)
    .map(key => {
      return {
        id: key,
        name: t(`units.functionId.${key}`)
      }
    })
}

const getSidcUnitSize = (sidc: string): string => {
  return sidc.substring(11, 12);
}

const setSidcUnitSize = (sidc: string, unitSize: string): string => {
  return `${sidc.substring(0, 11)}${unitSize}`;
}

const getSidcFunctionId = (sidc: string): string => {
  return sidc.substring(4, 10).split('-')[0]
}

const setSidcFunctionId = (sidc: string, functionId: string): string => {
  const prefix = sidc.substring(0, 4);
  const suffix = sidc.substring(10, 12);
  let fnId = functionId;
  while(fnId.length < 6) {
    fnId += '-'
  }
  return `${prefix}${fnId}${suffix}`;
}

const sidcToSymbol = (sidc: string, uniqueDesignation?: string, additionalInfo?: string, noOutline?: boolean) => {
  return new ms.Symbol(sidc,{
    size:30,
    outlineWidth: noOutline ? 0 : 1,
    uniqueDesignation: uniqueDesignation,
    additionalInformation: additionalInfo
  }).asSVG();
}

export {
  Affiliation,
  UnitSize,
  FunctionId,
  getSidcUnitSize,
  setSidcUnitSize,
  getSidcFunctionId,
  setSidcFunctionId,
  unitSizeOptions,
  functionIdOptions,
  sidcToSymbol
};
