import React from 'react';
import styled from 'styled-components';
import { Heading } from './Heading';
import { ArrowBack } from '@styled-icons/material/ArrowBack'
import {IconButton} from './IconButton';
import {PageContent} from './PageContent';
import { Keyframe } from '../utils/animations';

interface FullPageProps {
  heading: string
  loading: boolean
  closeHandler: any
  children: any
}

const BackArrowIcon = styled(ArrowBack)`
  color: ${props => props.theme.colors.main};
`

const BackButtonContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  margin: ${props => props.theme.spacing.margin}px;
`

const FullPageStyled = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  color: ${props => props.theme.colors.main};
  display: flex;
  z-index: 20;
  animation: ${Keyframe.fadeIn} 0.3s ease;
`;

export const FullPage = (props: FullPageProps) => {
  return (
    <FullPageStyled>
      <Heading>
        <BackButtonContainer>
          <IconButton clickHandler={props.closeHandler} size={'lg'}>
            <BackArrowIcon />
          </IconButton>
        </BackButtonContainer>
        <h2>{props.heading}</h2>
      </Heading>
      <PageContent loading={props.loading}>
        {props.children}
      </PageContent>
    </FullPageStyled>
  )
}
