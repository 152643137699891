import React from 'react';
import styled from 'styled-components';

import { IconButton } from './IconButton';
import { DeleteIcon } from './icons';

interface LayerItemProps {
  name: string,
  onClick: (e: any) => void
  onDeleteClick: (e: any) => void
}

const LayerItemStyled = styled.li`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  margin: ${props => props.theme.spacing.margin}px 0;
`;

export const LayerItem = (props: LayerItemProps) => {
  const { name, onClick, onDeleteClick } = props
  return (
    <LayerItemStyled onClick={onClick}>
      <span>{name}</span>
      <IconButton clickHandler={onDeleteClick} size={'sm'}>
        <DeleteIcon />
      </IconButton>
    </LayerItemStyled>
  )
}
