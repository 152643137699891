import styled from 'styled-components';
import {ArrowBack} from '@styled-icons/material/ArrowBack';
import {Edit} from '@styled-icons/material/Edit';
import {Delete} from '@styled-icons/material/Delete';
import {Add} from '@styled-icons/material/Add';
import {SpeakerPhone} from '@styled-icons/material/SpeakerPhone';
import {Close} from '@styled-icons/material/Close';
import {Lock} from '@styled-icons/material/Lock';
import {Map} from '@styled-icons/material/Map';

export const BackArrowIcon = styled(ArrowBack)`
  color: ${props => props.theme.colors.main};
`

export const EditIcon = styled(Edit)`
  color: ${props => props.theme.colors.main};
  height: ${props => props.theme.spacing.default*1.5}px;
  width: ${props => props.theme.spacing.default*1.5}px;
`

export const DeleteIcon = styled(Delete)`
  color: ${props => props.theme.colors.main};
  height: ${props => props.theme.spacing.default*1.5}px;
  width: ${props => props.theme.spacing.default*1.5}px;
`

export const AddIcon = styled(Add)`
  color: ${props => props.theme.colors.main};
  height: ${props => props.theme.spacing.default*2}px;
  width: ${props => props.theme.spacing.default*2}px;
`

export const DeviceIcon = styled(SpeakerPhone)`
  color: ${props => props.theme.colors.main};
  height: ${props => props.theme.spacing.default*2}px;
  width: ${props => props.theme.spacing.default*2}px;
`
export const CloseIcon = styled(Close)`
  color: ${props => props.theme.colors.main};
  height: ${props => props.theme.spacing.default*2}px;
  width: ${props => props.theme.spacing.default*2}px;
`
export const LockIcon = styled(Lock)`
  color: ${props => props.theme.colors.main};
  height: ${props => props.theme.spacing.default*2}px;
  width: ${props => props.theme.spacing.default*2}px;
`

export const MapIcon = styled(Map)`
  color: ${props => props.theme.colors.main};
  height: ${props => props.theme.spacing.default*2}px;
  width: ${props => props.theme.spacing.default*2}px;
`